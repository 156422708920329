import React, { Fragment, useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Styles from "./_user.module.css";
import UserBatchListDetails from "./UserBatchListDetails";
import { Link, useNavigate } from "react-router-dom";
import AxiosInstance from "../../api/AxiosInstance";
import { BsChatDotsFill } from "react-icons/bs";

import { SocketContext } from "../../ioContext/socketIo";
import { toast } from "react-toastify";
import { FiSearch } from "react-icons/fi";
import ReactPaginate from "react-paginate";
import { RotatingLines } from "react-loader-spinner";
import { useLocation } from "react-router-dom";
import Cookies from "js-cookie";
import { Modal, Button, Whisper, Tooltip } from "rsuite";
import { useRef } from "react";
import { ImAttachment, ImCross } from "react-icons/im";
import Select from "react-select";
import {
  Box,
  Card,
  CardContent,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  Button as MuiButton,
  Typography,
  Modal as MuiModal,
} from "@mui/material";
// text editor
import { EditorState, convertToRaw, ContentState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from "draftjs-to-html";
import img1 from "../../assets/card.webp";
import styles from "./Chat/Modal.module.css";
// import { Button } from "@mui/material";
const UserBatchData = () => {
  const location = useLocation();

  const [currentPageCount, setCurrentPageCount] = useState(1);
  const socket = useContext(SocketContext);
  const user1 = sessionStorage.getItem("user");
  const user = JSON.parse(user1);
  let [batchData, setBatchData] = useState([]);
  const [filteredItems, setFilteredItems] = useState([]);
  let [search1, setSearch1] = useState("");

  let [batchDataLocal, setBatchDataLocal] = useState([]);

  let navigate = useNavigate();

  let [nnotification, setNnotification] = useState({});
  let [batchBlock, setBatchBlock] = useState(false);

  const [page, setPage] = useState(0);

  let [search, setSearch] = useState("");
  let [searchdata, setSearchData] = useState(false);
  let [loading, setLoading] = useState(false);

  let { message } = useSelector(state => state.usersBatches);
  let [data, setSData] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [selected, setSelected] = useState([]);

  const handleCloseModal = () => {
    setOpenModal(false);
    setSelected([]);
  };

  //Like-------------------------------
  const [openModalLike, setOpenModalLike] = useState(false);

  const handleOpenModalLike = type => {
    let data = type.student_action.filter(ele => {
      return ele.like > 0;
    });

    setSelected(data);
    setOpenModalLike(true);
  };
  const handleCloseModalLike = () => {
    setOpenModalLike(false);
    setSelected([]);
  };
  //Dislike-------------------------------
  const [openModalDislike, setOpenModalDislike] = useState(false);

  const handleOpenModalDislike = type => {
    let data = type.student_action.filter(ele => {
      return ele.dislike > 0;
    });

    setSelected(data);
    setOpenModalDislike(true);
  };
  const handleCloseModalDislike = () => {
    setOpenModalDislike(false);
    setSelected([]);
  };

  //Share-------------------------------
  const [openModalShare, setOpenModalShare] = useState(false);

  const handleOpenModalShare = type => {
    let data = type.student_action.filter(ele => {
      return ele.share > 0;
    });

    setSelected(data);
    setOpenModalShare(true);
  };
  const handleCloseModalShare = () => {
    setOpenModalShare(false);
    setSelected([]);
  };

  //pagination
  const itemsPerPage = 18;
  let [totalcount, setTotalCount] = useState(0);
  const totalPages = Math.ceil(filteredItems.length / itemsPerPage);
  const [currentPage, setCurrentPage] = useState(0);
  const startIndex = currentPage * itemsPerPage;
  const endIndex = Math.min(startIndex + itemsPerPage, filteredItems.length);
  const currentItems = filteredItems.slice(startIndex, endIndex);

  //modal
  const [showModal, setShowModal] = useState(false);
  const [showFileModal, setShowFileModal] = useState(false);
  const [allmessage, setAllMessage] = useState("");
  let [loadingMsg, setLoadingMsg] = useState(false);

  // send message to  branch and subject
  let [loadingMsgFile, setLoadingMsgFile] = useState(false);
  let [branch, setBranch] = useState([]);
  let [branches, setBranches] = useState([]);
  let [purposes, setPurposes] = useState([]);
  let [subject, setSubject] = useState([]);
  let [dropbranch, setDropBranch] = useState(false);
  let [dropsubject, setDropSubject] = useState(false);
  const [showError, setShowError] = useState(false);
  const [showErrorSub, setShowErrorSub] = useState(false);
  const [showErrorFile, setShowErrorFile] = useState(false);
  const [share, setShare] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  let [files, setNewFiles] = useState([]);

  const [imagePreview, setImagePreview] = useState(null);
  const textareaRef = useRef(null);

  // text Editor
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [showEditor, setShowEditor] = useState(false);
  const handleEditorChange = state => {
    setEditorState(state);
  };

  // text Editor modal
  const [openEditor, setOpenEditor] = React.useState(false);

  const handleOpenEditor = () => {
    const contentState = ContentState.createFromText(allmessage);
    const newEditorState = EditorState.createWithContent(contentState);
    setEditorState(newEditorState);
    setShowModal(false);
    setOpenEditor(true);
    setShowEditor(!showEditor);
  };
  const handleCloseEditor = () => {
    setOpenEditor(false);
    setShowEditor(false);
    setEditorState(EditorState.createEmpty());
    setLoadingMsg(false);
    setAllMessage("");
    setPurposes([]);
    setShowModal(false);
    setBranch([]);
    setBranches([]);
    setPurposes([]);
    setSubject([]);
    setDropBranch(false);
    setDropSubject(false);
    setDropBranch(false);
    setDropSubject(false);
    setShowFileModal(false);
    setShowError(false);
    setShowErrorSub(false);
  };

  //  fetching Branch
  let LoadPurpose = e => {
    async function fetchData() {
      await AxiosInstance.get(`/employee/branch_batch_subjects`, {
        headers: {
          Authorization: `Bearer ${user.TOKEN}`,
        },
      })
        .then(x => {
          let BranchData = x.data.uniqueBranch;
          let branchlist = BranchData.map(ele => {
            return { value: ele, label: ele };
          });
          setBranch(branchlist);
        })
        .catch(y => {
          //(y);
        })
        .finally(z => {
          setIsLoading(false);
        });
    }
    fetchData();
  };

  let HandleBranch = () => {
    LoadPurpose();
    setDropBranch(true);
    setDropSubject(false);
  };
  //  fetching Subject
  let LoadSubject = e => {
    async function fetchData() {
      await AxiosInstance.get(`/employee/branch_batch_subjects`, {
        headers: {
          Authorization: `Bearer ${user.TOKEN}`,
        },
      })
        .then(x => {
          let BranchData = x.data.uniqueSubject;

          let branchlist = BranchData.map(ele => {
            return { value: ele, label: ele };
          });

          setPurposes(branchlist);
        })
        .catch(y => {
          //(y);
        })
        .finally(z => {
          setIsLoading(false);
        });
    }
    fetchData();
  };
  let HandleSubject = () => {
    LoadSubject();
    setDropBranch(false);
    setDropSubject(true);
  };

  useEffect(() => {
    socket.emit("chatwith", {
      senderId: user?._id,
      role: user?.role1,
      receiverId: null,
      device: "web",
    });
  }, []);
  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = "auto";
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  }, [allmessage]);
  useEffect(() => {
    const requestNotificationPermission = () => {
      if (!("Notification" in window)) {
      } else {
        Notification.requestPermission();
      }
    };

    requestNotificationPermission();
  }, []);

  useEffect(() => {
    const handleNotification = data => {
      new Notification(data?.name, {
        body: `${data?.msg?.messages?.text}\n${data?.msg?.messages?.code}`,
      });
    };

    socket.on("notification", handleNotification);

    return () => {
      socket.off("notification", handleNotification);
    };
  }, []);

  useEffect(() => {
    if (message?.status === 403) {
      sessionStorage.removeItem("user");
      sessionStorage.removeItem("token");
      sessionStorage.removeItem("device");
      Cookies.remove("name", { path: "", domain: ".qspiders.com" });

      navigate("/");
      Cookies.remove("student", { path: "", domain: ".qspiders.com" });
      Cookies.remove("visitedHomePage", { path: "", domain: ".qspiders.com" });
    } else {
      socket.emit("chatwith", {
        senderId: user?._id,
        role: user?.role1,
        receiverId: null,
        device: "web",
      });

      async function fetchData() {
        setLoading(true);

        await AxiosInstance.get(`users/batches`, {
          headers: {
            Authorization: `Bearer ${user?.TOKEN}`,
          },
        })
          .then(x => {
            setLoading(false);

            let data = x?.data?.BatchsDetails;

            setBatchData(data);
            const uniqueArray = data.filter((obj, index, arr) => {
              return (
                index === arr.findIndex(o => o.batchCode === obj.batchCode)
              );
            });

            let count = uniqueArray.length;

            setTotalCount(count);
            setBatchData(uniqueArray);
            setFilteredItems(uniqueArray);
          })
          .catch(y => {
            setLoading(false);

            if (y?.response?.status === 403) {
              sessionStorage.removeItem("user");
              sessionStorage.removeItem("token");
              sessionStorage.removeItem("device");
              Cookies.remove("name", { path: "", domain: ".qspiders.com" });

              navigate("/");

              Cookies.remove("student", { path: "", domain: ".qspiders.com" });
              Cookies.remove("visitedHomePage", {
                path: "",
                domain: ".qspiders.com",
              });
            }
          });
      }
      fetchData();
    }
  }, []);
  useEffect(() => {
    // Filter items based on search input
    const filtered = batchData?.filter(item =>
      item?.batchCode?.toLowerCase()?.includes(search?.toLowerCase())
    );
    setFilteredItems(filtered);
    setCurrentPage(0); // Reset to the first page on new search
  }, [search, batchData]);
  useEffect(() => {
    socket.on("batchclose_open", data => {
      setBatchBlock(true);
      setSData(data.result.res);
    });
  }, []);
  if (batchBlock === true) {
    toast.error(data, { position: "top-right" });
    function greeting() {}
    setTimeout(greeting, 3000);
    setBatchBlock(false);
    setSData("");
  }

  let handleBatchname = e => {
    let data = e.target.value;
    if (data.length > 0) {
      setSearchData(true);
    } else {
      setSearchData(false);
    }
    setSearch1(e.target.value?.toLowerCase());
    setSearch(e.target.value);
  };

  useEffect(() => {
    let data = batchData?.map(x => {
      return x?.batchCode == nnotification?.code
        ? { ...x, date: (x.date = nnotification?.msg?.createdAt) }
        : { ...x };
    });

    setBatchData(data);
  }, [nnotification]);
  const handlePageClick = event => {
    setCurrentPage(event.selected);
  };

  useEffect(() => {
    const storedPage = sessionStorage.getItem("currentPageCount");
    if (storedPage) {
      setCurrentPageCount(parseInt(storedPage));
      if (storedPage > 0) {
        setCurrentPage(parseInt(storedPage - 1));
      }
      sessionStorage.setItem("currentPageCount", 0);
    }
  }, []);
  let Handlepage = () => {
    sessionStorage.setItem("currentPageCount", currentPage + 1);
  };
  const handleSend = async e => {
    // dropbranch && branches?.length === 0
    if (dropbranch) {
      if (branches?.length === 0) {
        setShowError(true);
      } else if (allmessage.trim() === "") {
        toast.warn("Please type your message");
      } else {
        setLoadingMsg(true);

        let payload = {
          username: user?.username,
          role: user?.role1,
          senderId: user?._id,
          branch: branches.map(x => x.value),
          // subject: purposes.map(x => x.value),
          text: allmessage,
          share: share,
        };
        await AxiosInstance.post("/chat/sendMessageForallBatch", payload, {
          headers: {
            Authorization: `Bearer ${user.TOKEN}`,
          },
        })
          .then(x => {
            if (x?.status === 200) {
              toast.success("Message sent successfully", {
                position: "top-right",
              });
            }
            setLoadingMsg(false);
            setAllMessage("");
            setPurposes([]);
            setShowModal(false);
            setBranch([]);
            setBranches([]);
            setPurposes([]);
            setSubject([]);
            setDropBranch(false);
            setDropSubject(false);
            setDropBranch(false);
            setDropSubject(false);
            setShowFileModal(false);
            setShowError(false);
            setShowErrorSub(false);
          })
          .catch(y => {
            if (y?.status === 200) {
              toast.error("There is an error while sending a message");
            }
            setLoadingMsg(false);

            setAllMessage("");

            setPurposes([]);
            setShowModal(false);
            setBranch([]);
            setBranches([]);
            setPurposes([]);
            setSubject([]);
            setDropBranch(false);
            setDropSubject(false);
            setDropBranch(false);
            setDropSubject(false);
            setShowFileModal(false);
            setShowError(false);
            setShowErrorSub(false);

            if (y?.response?.status === 403) {
              // ErrorHandle();
            }
          });
      }
    } else if (dropsubject) {
      if (subject.length < 1) {
        setShowErrorSub(true);
      } else if (allmessage.trim() === "") {
        toast.warn("Please type your message");
      } else {
        setLoadingMsg(true);

        let payload = {
          username: user?.username,
          role: user?.role1,
          senderId: user?._id,
          //  branch: branches.map(x => x.value),
          subject: subject.map(x => x.value),
          text: allmessage,
          share: share,
        };
        await AxiosInstance.post("/chat/sendMessageForallBatch", payload, {
          headers: {
            Authorization: `Bearer ${user.TOKEN}`,
          },
        })
          .then(x => {
            if (x?.status === 200) {
              toast.success("Message sent successfully", {
                position: "top-right",
              });
            }
            setLoadingMsg(false);

            setAllMessage("");

            setShowModal(false);
            setBranch([]);
            setBranches([]);
            setPurposes([]);
            setSubject([]);
            setDropBranch(false);
            setDropSubject(false);
            setDropBranch(false);
            setDropSubject(false);
            setShowFileModal(false);
            setShowError(false);
            setShowErrorSub(false);
          })
          .catch(y => {
            if (y?.status === 200) {
              toast.error("There is an error while sending a message");
            }
            setLoadingMsg(false);
            setShowModal(false);
            setAllMessage("");

            setBranch([]);
            setBranches([]);
            setPurposes([]);
            setSubject([]);
            setDropBranch(false);
            setDropSubject(false);
            setDropBranch(false);
            setDropSubject(false);
            setShowFileModal(false);
            setShowError(false);
            setShowErrorSub(false);

            if (y?.response?.status === 403) {
              // ErrorHandle();
            }
          });
      }
    }
  };
  // function previewFile(e) {
  //   // Gettting Selected File (user can select multiple but we are choosing only one)
  //   const selectedFile = e.target.files[0]; // Get only the first selected file
  //   setNewFiles([selectedFile]);
  // }
  function previewFile(e) {
    // Reading New File (open file Picker Box)

    // if () {
    const reader = new FileReader();

    const selectedFile = e.target.files;
    //(e.target.files);
    setNewFiles([...files, ...e.target.files]);
    const previews = [];
    for (let i = 0; i < selectedFile.length; i++) {
      const reader = new FileReader();
      const file = selectedFile[i];
      reader.readAsDataURL(file);
      reader.onload = readerEvent => {
        if (file.type.includes("image")) {
          previews.push(
            <img
              src={readerEvent.target.result}
              alt=""
              width={250}
              height={200}
            />
          );
        } else if (file.type.includes("video")) {
        }
        setImagePreview(previews);
      };
    }
    // }
  }
  const handleChangePurpose = e => {
    setIsOpen(false);
    setShowErrorSub(false);
    // setPurposes(e);
    if (e) {
      setSubject(e);
    } else {
      setSubject([]);
    }
  };

  const handleChangebranch = e => {
    setBranches(e);
    setShowError(false);
  };

  let handleEditorSend = async e => {
    const contentState = editorState.getCurrentContent();
    // Check if the content is empty
    const isEmpty =
      !contentState.hasText() || contentState.getPlainText().trim() === "";
    if (!isEmpty) {
      const contentState = editorState.getCurrentContent();

      const rawContentState = convertToRaw(contentState);

      // let data = JSON.stringify(rawContentState);
      let content = draftToHtml(rawContentState);
      setAllMessage(content);

      if (dropbranch) {
        if (branches?.length === 0) {
          setShowError(true);
        } else {
          setLoadingMsg(true);

          let payload = {
            username: user?.username,
            role: user?.role1,
            senderId: user?._id,
            branch: branches.map(x => x.value),
            // subject: purposes.map(x => x.value),
            text: content,
            html: true,
            share: share,
          };
          await AxiosInstance.post("/chat/sendMessageForallBatch", payload, {
            headers: {
              Authorization: `Bearer ${user.TOKEN}`,
            },
          })
            .then(x => {
              if (x?.status === 200) {
                toast.success("Message sent successfully", {
                  position: "top-right",
                });
              }
              setLoadingMsg(false);
              setAllMessage("");
              setPurposes([]);
              setShowModal(false);
              setBranch([]);
              setBranches([]);
              setPurposes([]);
              setSubject([]);
              setDropBranch(false);
              setDropSubject(false);
              setDropBranch(false);
              setDropSubject(false);
              setShowFileModal(false);
              setShowError(false);
              setShowErrorSub(false);
            })
            .catch(y => {
              if (y?.status === 200) {
                toast.error("There is an error while sending a message");
              }
              setLoadingMsg(false);

              setAllMessage("");

              setPurposes([]);
              setShowModal(false);
              setBranch([]);
              setBranches([]);
              setPurposes([]);
              setSubject([]);
              setDropBranch(false);
              setDropSubject(false);
              setDropBranch(false);
              setDropSubject(false);
              setShowFileModal(false);
              setShowError(false);
              setShowErrorSub(false);

              if (y?.response?.status === 403) {
                // ErrorHandle();
              }
            });
        }
      } else if (dropsubject) {
        if (subject.length < 1) {
          setShowErrorSub(true);
        }
        // else if (allmessage.trim() === "") {
        //   toast.warn("Please type your message");
        // }
        else {
          setLoadingMsg(true);

          let payload = {
            username: user?.username,
            role: user?.role1,
            senderId: user?._id,
            //  branch: branches.map(x => x.value),
            subject: subject.map(x => x.value),
            text: content,
            html: true,
            share: share,
          };
          await AxiosInstance.post("/chat/sendMessageForallBatch", payload, {
            headers: {
              Authorization: `Bearer ${user.TOKEN}`,
            },
          })
            .then(x => {
              if (x?.status === 200) {
                toast.success("Message sent successfully", {
                  position: "top-right",
                });
              }
              setLoadingMsg(false);

              setAllMessage("");

              setShowModal(false);
              setBranch([]);
              setBranches([]);
              setPurposes([]);
              setSubject([]);
              setDropBranch(false);
              setDropSubject(false);
              setDropBranch(false);
              setDropSubject(false);
              setShowFileModal(false);
              setShowError(false);
              setShowErrorSub(false);
            })
            .catch(y => {
              if (y?.status === 200) {
                toast.error("There is an error while sending a message");
              }
              setLoadingMsg(false);
              setShowModal(false);
              setAllMessage("");

              setBranch([]);
              setBranches([]);
              setPurposes([]);
              setSubject([]);
              setDropBranch(false);
              setDropSubject(false);
              setDropBranch(false);
              setDropSubject(false);
              setShowFileModal(false);
              setShowError(false);
              setShowErrorSub(false);

              if (y?.response?.status === 403) {
                // ErrorHandle();
              }
            });
        }
      }
      setEditorState(EditorState.createEmpty());
      setOpenEditor(false);
      setShowEditor(false);
    }
    setAllMessage("");
  };

  let handleSubmit = async e => {
    setLoadingMsgFile(true);
    if (branches?.length > 0) {
      e.preventDefault();
      if (files?.length > 0) {
        let result = Array.isArray(files);
        let abc = files?.map(x => x);
        try {
          let payload = {
            files: abc,
            sender: user._id,
          };
          const options = {
            headers: {
              Authorization: `Bearer ${user.TOKEN}`,
              "Content-Type": "multipart/form-data",
            },
          };
          await AxiosInstance.post("/chat/newmsgfiles", payload, options)
            .then(x => {
 
              let filesData = x.data.files;
              const fileText = filesData.map(ele => {
                return {
                  ...ele,
                  text: allmessage,
                };
              });
            
              let payload1 = {
                username: user.username,
                role: user?.role1,
                senderId: user._id,
                files: fileText,
                share: share,
                // text: allmessage,
                branch: branches.map(x => x.value),
              };

              AxiosInstance.post("chat/SendMessageForallBatch", payload1, {
                headers: {
                  Authorization: `Bearer ${user.TOKEN}`,
                },
              })
                .then(x => {
         
                  if (x?.status === 200) {
                    toast.success("Message sent successfully", {
                      position: "top-right",
                    });
                  }
                  setLoadingMsgFile(false);
                  setShowFileModal(false);
                  setShowModal(false);
                  setNewFiles([]);
                  setLoadingMsg(false);
                  setAllMessage("");
                  setPurposes([]);
                  setShowModal(false);
                  setBranch([]);
                  setBranches([]);
                  setPurposes([]);
                  setSubject([]);
                  setDropBranch(false);
                  setDropSubject(false);
                  setDropBranch(false);
                  setDropSubject(false);
                  setShowFileModal(false);
                  setShowError(false);
                  setShowErrorSub(false);
                })
                .catch(y => {
                  if (y?.status === 200) {
                    toast.error("There is an error while sending a message", {
                      position: "top-right",
                    });
                  }
                  setLoadingMsgFile(false);
                  setShowFileModal(false);
                  setShowModal(false);
                  setNewFiles([]);
                  setLoadingMsg(false);
                  setAllMessage("");
                  setPurposes([]);
                  setShowModal(false);
                  setBranch([]);
                  setBranches([]);
                  setPurposes([]);
                  setSubject([]);
                  setDropBranch(false);
                  setDropSubject(false);
                  setDropBranch(false);
                  setDropSubject(false);
                  setShowFileModal(false);
                  setShowError(false);
                  setShowErrorSub(false);

                  if (y?.response?.status === 403) {
                    // ErrorHandle();
                  }
                });
              setImagePreview(null);
            })
            .catch(() => {});
        } catch (error) {
          if (error?.status === 200) {
            toast.error("There is an error while sending a message", {
              position: "top-right",
            });
          }
          setLoadingMsgFile(false);
          setShowFileModal(false);
          setShowModal(false);
          setNewFiles([]);
          setLoadingMsg(false);
          setAllMessage("");
          setPurposes([]);
          setShowModal(false);
          setBranch([]);
          setBranches([]);
          setPurposes([]);
          setSubject([]);
          setDropBranch(false);
          setDropSubject(false);
          setDropBranch(false);
          setDropSubject(false);
          setShowFileModal(false);
          setShowError(false);
          setShowErrorSub(false);
        }
      } else {
        toast.warn("Please select file");
      }
    } else if (subject.length > 0) {
      e.preventDefault();
      if (files?.length > 0) {
        let result = Array.isArray(files);
        let abc = files?.map(x => x);
        try {
          let payload = {
            files: abc,
            sender: user._id,
          };
          //(payload);
          const options = {
            headers: {
              Authorization: `Bearer ${user.TOKEN}`,
              "Content-Type": "multipart/form-data",
            },
          };
          await AxiosInstance.post("/chat/newmsgfiles", payload, options)
            .then(x => {
              let filesData = x.data.files;
              const fileText = filesData.map(ele => {
                return {
                  ...ele,
                  text: allmessage,
                };
              });
              let payload1 = {
                username: user.username,
                role: user?.role1,
                senderId: user._id,
                files: fileText,
                subject: purposes.map(x => x.value),
                share: share,
              };

              AxiosInstance.post("chat/SendMessageForallBatch", payload1, {
                headers: {
                  Authorization: `Bearer ${user.TOKEN}`,
                },
              })
                .then(x => {
                  if (x?.status === 200) {
                    toast.success("Message sent successfully", {
                      position: "top-right",
                    });
                  }
                  setLoadingMsgFile(false);
                  setShowFileModal(false);
                  setNewFiles([]);
                  setLoadingMsg(false);
                  setAllMessage("");
                  setPurposes([]);
                  setShowModal(false);
                  setBranch([]);
                  setBranches([]);
                  setPurposes([]);
                  setSubject([]);
                  setDropBranch(false);
                  setDropSubject(false);
                  setDropBranch(false);
                  setDropSubject(false);
                  setShowFileModal(false);
                  setShowError(false);
                  setShowErrorSub(false);
                })
                .catch(y => {
                  if (y?.status === 200) {
                    toast.error("There is an error while sending a message", {
                      position: "top-right",
                    });
                  }
                  setLoadingMsgFile(false);
                  setShowFileModal(false);
                  setNewFiles([]);
                  setLoadingMsg(false);
                  setAllMessage("");
                  setPurposes([]);
                  setShowModal(false);
                  setBranch([]);
                  setBranches([]);
                  setPurposes([]);
                  setSubject([]);
                  setDropBranch(false);
                  setDropSubject(false);
                  setDropBranch(false);
                  setDropSubject(false);
                  setShowFileModal(false);
                  setShowError(false);
                  setShowErrorSub(false);
                  if (y?.response?.status === 403) {
                    // ErrorHandle();
                  }
                });
              setImagePreview(null);
            })
            .catch(() => {});
        } catch (error) {
          if (error?.status === 200) {
            toast.error("There is an error while sending a message", {
              position: "top-right",
            });
          }
          setLoadingMsgFile(false);
          setShowFileModal(false);
          setNewFiles([]);
          setLoadingMsg(false);
          setAllMessage("");
          setPurposes([]);
          setShowModal(false);
          setBranch([]);
          setBranches([]);
          setPurposes([]);
          setSubject([]);
          setDropBranch(false);
          setDropSubject(false);
          setDropBranch(false);
          setDropSubject(false);
          setShowFileModal(false);
          setShowError(false);
          setShowErrorSub(false);
        }
      } else {
        toast.warn("Please select file");
      }
    }
  };
  const [pastedImage, setPastedImage] = useState(null);

  // const handlePaste = e => {
  //   e.preventDefault(); // Prevent the default paste behavior

  //   const clipboardData = e.clipboardData || window.clipboardData;
  //   const pastedText = clipboardData.getData("text"); // Get the text from the clipboard
  //   if (pastedText) {
  //     setAllMessage(pastedText);
  //   }
  //   // Handle image paste
  //   const items = clipboardData.items;
  //   for (let i = 0; i < items.length; i++) {
  //     const item = items[i];
  //     if (item.type.indexOf("image") !== -1) {
  //       setShowFileModal(true);
  //       const file = item.getAsFile();
  //       const imageUrl = URL.createObjectURL(file);
  //       console.log(imageUrl);
  //       setPastedImage(imageUrl); // Store pasted image
  //       break;
  //     }
  //   }
  // };

  const handlePaste = e => {
    e.preventDefault(); // Prevent the default paste behavior

    const clipboardData = e.clipboardData || window.clipboardData;
    const pastedText = clipboardData.getData("text"); // Get the text from the clipboard

    if (pastedText) {
      // Append the pasted text to the current message
      setAllMessage(prevMessage => {
      
        const updatedMessage = prevMessage + pastedText;
 
        return updatedMessage;
      });
    }

    // Handle image paste (if required)
    const items = clipboardData.items;
    for (let i = 0; i < items.length; i++) {
      const item = items[i];
      if (item.type.indexOf("image") !== -1) {
        setShowFileModal(true);
        const file = item.getAsFile();
        const imageUrl = URL.createObjectURL(file);

        setPastedImage(imageUrl); // Store pasted image
        break;
      }
    }
  };
  useEffect(() => {
    if (pastedImage) {
      fetch(pastedImage)
        .then(res => res.blob())
        .then(blob => {
          const file = new File([blob], "pasted-image.png", {
            type: blob.type,
          });
          setNewFiles([...files, file]); // Add the pasted image to the files array
          setImagePreview([
            <img src={pastedImage} alt="" width={250} height={200} />,
          ]);
        });
    }
  }, [pastedImage]);
  let HandleCloseModal = () => {
    setShowModal(false);
    setBranch([]);
    setBranches([]);
    setPurposes([]);
    setSubject([]);
    setDropBranch(false);
    setDropSubject(false);
    setDropBranch(false);
    setDropSubject(false);
    setAllMessage("");
    setShowFileModal(false);
    setShowError(false);
    setShowErrorSub(false);
  };

  let handleRemoveImage = e => {
    setNewFiles([]);
  };

  // for shared messages
  const [showModalMsg, setShowModalMsg] = useState(false);

  let HandleCloseMsgModal = () => {
    setShowModalMsg(false);
  };
  let HandleClickLink = event => {
    HandleCloseModal();
    navigate(`/user-dashboard/sharedmsg`);
  };
  let [scroll, setScroll] = useState(0);

  return (
    <>
      <Modal
        backdrop="static"
        // keyboard={false}
        role="alertdialog"
        open={openEditor}
        onClose={handleCloseEditor}
      >
        <div className={Styles.HeaderPoll}>
          <span onClick={handleCloseEditor}>
            <ImCross />
          </span>
          <h4>Text Editor</h4>
        </div>

        <Modal.Body>
          <Editor
            editorState={editorState}
            wrapperClassName="chatEditorWrapper"
            editorClassName="chatEditor"
            onEditorStateChange={handleEditorChange}
            placeholder="Write something..."
            toolbar={{
              options: [
                "inline",
                // "blockType",
                // "fontSize",
                // "list",
                // "textAlign",
                "colorPicker",
                // "link",
                // "embedded",
                "emoji",
                // "image",
                // "remove",
                "history",
              ],
              inline: {
                options: ["bold", "italic", "underline", "strikethrough"],
              },
              colorPicker: {
                colors: [
                  "#000000",
                  "#ffffff",
                  "#ff0000",
                  "#00ff00",
                  "#0000ff",
                  "#FFFF00",
                ],
              },
            }}
          />
        </Modal.Body>
        <Modal.Footer>
          {" "}
          <div
            style={{ display: "flex", gap: "1%", justifyContent: "flex-end" }}
          >
            {loadingMsg ? (
              <Button appearance="primary">
                <RotatingLines
                  strokeColor="#f16f1d"
                  strokeWidth="5"
                  animationDuration="0.75"
                  width="20"
                  visible={true}
                />
              </Button>
            ) : (
              <>
                <MuiButton
                  type="button"
                  variant="contained"
                  color="error"
                  onClick={handleCloseEditor}
                >
                  cancel
                </MuiButton>
                <MuiButton
                  type="button"
                  variant="contained"
                  color="warning"
                  onClick={handleEditorSend}
                >
                  Send
                </MuiButton>
              </>
            )}
            <button
              type="submit"
              style={{ display: "none" }}
              className="chatSubmitButton"
              onClick={handleEditorSend}
            >
              Send
            </button>
          </div>
        </Modal.Footer>
      </Modal>
      {/* Modal for displaying names */}
      <Modal open={openModal} onClose={handleCloseModal}>
        <Modal.Header>
          <Modal.Title>
            <h6 className={Styles.AllBatchHead}> Total Number of Students</h6>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selected?.student_action?.length > 0 ? (
            selected?.student_action?.map((name, index) => (
              <Typography key={name.username} variant="body2" gutterBottom>
                {name.username}
              </Typography>
            ))
          ) : (
            <Typography variant="body2" color="text.secondary">
              No names available.
            </Typography>
          )}
        </Modal.Body>
      </Modal>
      {/* Modal for Likes displaying names */}
      <Modal open={openModalLike} onClose={handleCloseModalLike}>
        <Modal.Header>
          <Modal.Title>
            <h6 className={Styles.AllBatchHead}>Students liked message</h6>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selected?.length > 0 ? (
            selected?.map((name, index) => (
              <Typography key={name.username} variant="body2" gutterBottom>
                {name.username}
              </Typography>
            ))
          ) : (
            <Typography variant="body2" color="text.secondary">
              No names available.
            </Typography>
          )}
        </Modal.Body>
      </Modal>
      {/* Modal for Dislikes displaying names */}
      <Modal open={openModalDislike} onClose={handleCloseModalDislike}>
        <Modal.Header>
          <Modal.Title>
            <h6 className={Styles.AllBatchHead}>Students disliked message</h6>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selected?.length > 0 ? (
            selected?.map((name, index) => (
              <Typography key={name.username} variant="body2" gutterBottom>
                {name.username}
              </Typography>
            ))
          ) : (
            <Typography variant="body2" color="text.secondary">
              No names available.
            </Typography>
          )}
        </Modal.Body>
      </Modal>
      {/* Modal for shared displaying names */}
      <Modal open={openModalShare} onClose={handleCloseModalShare}>
        <Modal.Header>
          <Modal.Title>
            <h6 className={Styles.AllBatchHead}>Students disliked message</h6>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selected?.length > 0 ? (
            selected?.map((name, index) => (
              <Typography key={name.username} variant="body2" gutterBottom>
                {name.username} - <span>{name.share}</span>
              </Typography>
            ))
          ) : (
            <Typography variant="body2" color="text.secondary">
              No names available.
            </Typography>
          )}
        </Modal.Body>
      </Modal>

      {loading === true ? (
        <div className={Styles.loader}>
          <RotatingLines
            strokeColor="#f16f1d"
            strokeWidth="5"
            animationDuration="0.75"
            width="96"
            visible={true}
          />
        </div>
      ) : (
        ""
      )}
      {batchData?.length > 0 ? (
        <aside>
          <div className={Styles.Search}>
            <span>
              <FiSearch />
            </span>
            <input
              id="search"
              name="search"
              value={search}
              type="search"
              placeholder="Search BatchCode"
              onChange={e => handleBatchname(e)}
            />
          </div>
        </aside>
      ) : (
        ""
      )}
      <section id={Styles.BatchGridLayout}>
        <article>
          {batchData?.length === 0 ? (
            ""
          ) : (
            <>
              <div
                className={Styles.container1}
                style={
                  {
                    // backgroundImage: `url('${img1}')`,
                    // width: "98%",
                  }
                }
              >
                <h1>Recent Chats</h1>
                <p>
                  <span>
                    <strong>All Students</strong>
                  </span>
                  <span></span>
                </p>
                <p>
                  <span>
                    <strong></strong>
                  </span>
                  <span></span>
                </p>

                <Link to="/user-dashboard/allChat">
                  <span>
                    <BsChatDotsFill />
                  </span>
                  chat with students
                </Link>
              </div>
              <div
                className={Styles.container1}
                style={
                  {
                    // backgroundImage: `url('${img1}')`,
                    // width: "98%",
                  }
                }
              >
                <h1>Send Chat to all students</h1>
                <p>
                  <span>
                    <strong>All Batches</strong>
                  </span>
                  <span></span>
                </p>
                <p>
                  <span>
                    <strong></strong>
                  </span>
                  <span></span>
                </p>
                <div className={Styles.Linkbtn}>
                  <MuiButton
                    // className={Styles.Messagebtn}
                    onClick={() => setShowModal(true)}
                  >
                    {/* <span> */}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={18}
                      height={18}
                      viewBox="0 0 24 24"
                    >
                      <path
                        fill="#fff"
                        d="M12 8H4a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h1v4a1 1 0 0 0 1 1h2a1 1 0 0 0 1-1v-4h3l5 4V4zm9.5 4c0 1.71-.96 3.26-2.5 4V8c1.53.75 2.5 2.3 2.5 4"
                      ></path>
                    </svg>
                    {/* </span> */}
                    Broadcast
                  </MuiButton>
                </div>

                <Modal
                  open={showModal}
                  size="md"
                  onClose={() => HandleCloseModal()}
                >
                  <Modal.Header>
                    <Modal.Title>
                      {" "}
                      <h6 className={Styles.AllBatchHead}>Send Message</h6>
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body
                    style={{
                      overflow: "unset",
                      maxheight: "none",
                    }}
                  >
                    <div>
                      {dropbranch === false && dropsubject === false && (
                        <>
                          <div className={Styles.HeadBlock}>
                            <h4 className={Styles.HeadMsg}>
                              Select any one to send message
                            </h4>
                          </div>
                          <div className={Styles.BtnBlock}>
                            <Button
                              appearance="primary"
                              onClick={HandleBranch}
                              className={Styles.MessageBtn}
                            >
                              Branch
                            </Button>
                            <Button
                              appearance="primary"
                              onClick={HandleSubject}
                              className={Styles.MessageBtn}
                            >
                              Subject
                            </Button>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",

                              marginTop: "10px",
                            }}
                          >
                            {
                              // <Link
                              //   to={{
                              //     pathname: "/user-dashboard/sharedmsg",
                              //   }}
                              //   state={{ likeShareList }}
                              // >
                              <MuiButton
                                variant="outlined"
                                onClick={e => HandleClickLink(e)}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width={18}
                                  height={18}
                                  viewBox="0 0 24 24"
                                >
                                  <path
                                    fill="#313030"
                                    d="m13.576 17.271l-5.11-2.787a3.5 3.5 0 1 1 0-4.968l5.11-2.787a3.5 3.5 0 1 1 .958 1.755l-5.11 2.787a3.5 3.5 0 0 1 0 1.457l5.11 2.788a3.5 3.5 0 1 1-.958 1.755"
                                  ></path>
                                </svg>
                                Shared to other application message list
                              </MuiButton>
                              // </Link>
                            }
                          </div>
                        </>
                      )}
                      <Box>
                        {dropbranch && (
                          <>
                            <h4>Branch</h4>
                            <Select
                              options={branch}
                              isMulti
                              closeMenuOnSelect={false}
                              onChange={handleChangebranch}
                              value={branches}
                              className={Styles.Dropdownmsg}
                            />
                            {showError && (
                              <p style={{ color: "red", padding: "5px" }}>
                                Please select at least one branch
                              </p>
                            )}
                          </>
                        )}
                        {dropsubject && (
                          <>
                            <h4>Subject</h4>
                            <Select
                              options={purposes}
                              isMulti
                              closeMenuOnSelect={false}
                              onChange={handleChangePurpose}
                              value={subject}
                              className={Styles.Dropdownmsg}
                            />
                            {showErrorSub && (
                              <p style={{ color: "red", padding: "5px" }}>
                                Please select at least one Subject
                              </p>
                            )}
                          </>
                        )}
                      </Box>

                      {(dropbranch === true || dropsubject === true) && (
                        <Box>
                          <Box>
                            {" "}
                            <Grid item xs={6} sm={4}>
                              <FormControlLabel
                                control={<Checkbox sx={{ color: "#f16f1d" , marginLeft:"5px"}} />}
                                label="Share to other application"
                                onChange={e => setShare(e.target.checked)}
                              />
                            </Grid>
                          </Box>
                          <form className={Styles.MsgFormBlock}>
                            {showFileModal === false ? (
                              <div className={Styles.messageinputall}>
                                {" "}
                                <textarea
                                  ref={textareaRef}
                                  value={allmessage}
                                  onChange={e => setAllMessage(e.target.value)}
                                  onPaste={handlePaste}
                                  placeholder="Type your message..."
                                  required
                                />
                              </div>
                            ) : (
                              <div
                                style={{
                                  marginTop: "8px",
                                  display: "flex",
                                  width: "91%",
                                }}
                              >
                                <section style={{ width: "100%" }}>
                                  {files?.length <= 0 ? (
                                    <IconButton
                                      color="white"
                                      title="Upload File"
                                      aria-label="upload picture"
                                      component="label"
                                      style={{
                                        border: "2px solid",
                                        borderRadius: "5px",
                                        fontSize: "16px",
                                        background: "rgb(245, 124, 0)",
                                        fontWeight: "bold",
                                        color: "#fff",
                                        marginLeft: "20px",
                                      }}
                                    >
                                      Upload File
                                      <input
                                        type="file"
                                        onChange={previewFile}
                                        hidden
                                      />
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="20"
                                        height="20"
                                        viewBox="0 0 24 24"
                                      >
                                        <g fill="none">
                                          <path d="M24 0v24H0V0zM12.593 23.258l-.011.002l-.071.035l-.02.004l-.014-.004l-.071-.035q-.016-.005-.024.005l-.004.01l-.017.428l.005.02l.01.013l.104.074l.015.004l.012-.004l.104-.074l.012-.016l.004-.017l-.017-.427q-.004-.016-.017-.018m.265-.113l-.013.002l-.185.093l-.01.01l-.003.011l.018.43l.005.012l.008.007l.201.093q.019.005.029-.008l.004-.014l-.034-.614q-.005-.019-.02-.022m-.715.002a.02.02 0 0 0-.027.006l-.006.014l-.034.614q.001.018.017.024l.015-.002l.201-.093l.01-.008l.004-.011l.017-.43l-.003-.012l-.01-.01z" />
                                          <path
                                            fill="#fff"
                                            d="M20 14.5a1.5 1.5 0 0 1 1.5 1.5v4a2.5 2.5 0 0 1-2.5 2.5H5A2.5 2.5 0 0 1 2.5 20v-4a1.5 1.5 0 0 1 3 0v3.5h13V16a1.5 1.5 0 0 1 1.5-1.5M13.237 3.12l4.066 4.067a1.5 1.5 0 1 1-2.121 2.121L13.5 7.626V16a1.5 1.5 0 0 1-3 0V7.626L8.818 9.308a1.5 1.5 0 1 1-2.121-2.121l4.066-4.066a1.75 1.75 0 0 1 2.474 0Z"
                                          />
                                        </g>
                                      </svg>
                                    </IconButton>
                                  ) : (
                                    <>
                                      <Button
                                        title="Delete file"
                                        color="error"
                                        onClick={handleRemoveImage}
                                        style={{
                                          fontSize: "25px",
                                          marginLeft: "20px",
                                        }}
                                      >
                                        <svg
                                          fill="crimson"
                                          height="1em"
                                          viewBox="0 0 448 512"
                                        >
                                          <path d="M170.5 51.6L151.5 80h145l-19-28.4c-1.5-2.2-4-3.6-6.7-3.6H177.1c-2.7 0-5.2 1.3-6.7 3.6zm147-26.6L354.2 80H368h48 8c13.3 0 24 10.7 24 24s-10.7 24-24 24h-8V432c0 44.2-35.8 80-80 80H112c-44.2 0-80-35.8-80-80V128H24c-13.3 0-24-10.7-24-24S10.7 80 24 80h8H80 93.8l36.7-55.1C140.9 9.4 158.4 0 177.1 0h93.7c18.7 0 36.2 9.4 46.6 24.9zM80 128V432c0 17.7 14.3 32 32 32H336c17.7 0 32-14.3 32-32V128H80zm80 64V400c0 8.8-7.2 16-16 16s-16-7.2-16-16V192c0-8.8 7.2-16 16-16s16 7.2 16 16zm80 0V400c0 8.8-7.2 16-16 16s-16-7.2-16-16V192c0-8.8 7.2-16 16-16s16 7.2 16 16zm80 0V400c0 8.8-7.2 16-16 16s-16-7.2-16-16V192c0-8.8 7.2-16 16-16s16 7.2 16 16z" />
                                        </svg>
                                      </Button>
                                    </>
                                  )}
                                  <div
                                    className={styles.previewContainer}
                                    style={{
                                      display: "flex",
                                      flexWrap: "wrap",
                                    }}
                                  >
                                    {files?.length > 0 &&
                                      files.map((file, index) => (
                                        <div
                                          className={
                                            styles.imagePreviewContainer
                                          }
                                          key={index}
                                        >
                                          {file.type &&
                                          file.type.includes("image") ? (
                                            <React.Fragment>
                                              <img
                                                src={URL.createObjectURL(file)}
                                                alt=""
                                                width={100}
                                                height={100}
                                                className={styles.imagePreview}
                                              />
                                            </React.Fragment>
                                          ) : (
                                            <div
                                              className={styles.nonImagePreview}
                                            >
                                              <div className={styles.fileIcon}>
                                                <span
                                                  role="img"
                                                  aria-label="File Icon"
                                                >
                                                  📄
                                                </span>
                                              </div>
                                              <div className={styles.fileName}>
                                                {file.name}
                                              </div>
                                            </div>
                                          )}
                                        </div>
                                      ))}
                                  </div>
                                  <p
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                    }}
                                  >
                                    {files &&
                                      files[0]?.name?.slice(
                                        files[0]?.name?.length - 35,
                                        files[0]?.name?.length
                                      )}
                                  </p>
                                  <div className={Styles.messageinputall}>
                                    {" "}
                                    <textarea
                                      ref={textareaRef}
                                      value={allmessage}
                                      onChange={e =>
                                        setAllMessage(e.target.value)
                                      }
                                      onPaste={handlePaste}
                                      placeholder="Type your message..."
                                      required
                                    />
                                  </div>
                                </section>
                              </div>
                            )}

                            {loadingMsg ? (
                              <Button appearance="primary">
                                <RotatingLines
                                  strokeColor="#f16f1d"
                                  strokeWidth="5"
                                  animationDuration="0.75"
                                  width="20"
                                  visible={true}
                                />
                              </Button>
                            ) : (
                              <>
                                {showFileModal === true ? (
                                  <>
                                    <span
                                      className={Styles.backtotext}
                                      onClick={() => setShowFileModal(false)}
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="26"
                                        height="26"
                                        viewBox="0 0 24 24"
                                      >
                                        <path
                                          fill="#333"
                                          d="m4 10l-.707.707L2.586 10l.707-.707zm17 8a1 1 0 1 1-2 0zM8.293 15.707l-5-5l1.414-1.414l5 5zm-5-6.414l5-5l1.414 1.414l-5 5zM4 9h10v2H4zm17 7v2h-2v-2zm-7-7a7 7 0 0 1 7 7h-2a5 5 0 0 0-5-5z"
                                        />
                                      </svg>
                                    </span>
                                    <Button
                                      appearance="primary"
                                      onClick={handleSubmit}
                                      className={Styles.MsgSendBtn}
                                      style={{ marginTop: "50px" }}
                                    >
                                      Send
                                    </Button>
                                  </>
                                ) : (
                                  <>
                                    {((dropbranch && branches?.length > 0) ||
                                      (dropsubject && subject?.length > 0)) && (
                                      <aside
                                        style={{
                                          position: "relative",
                                          margin: "1%",
                                          display: "flex",
                                          justifyContent: "center",
                                          alignItems: "center",
                                        }}
                                      >
                                        <div onClick={handleOpenEditor}>
                                          <Whisper
                                            followCursor
                                            speaker={
                                              <Tooltip>{"text edit"}</Tooltip>
                                            }
                                          >
                                            <span>
                                              <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width={24}
                                                height={24}
                                                viewBox="0 0 32 32"
                                              >
                                                <path
                                                  fill="#475569"
                                                  d="M9.5 2c.502 0 .956.3 1.152.763l5.072 11.989l-1.908 1.907L12.48 13.5H6.52l-1.37 3.237a1.25 1.25 0 0 1-2.302-.974l5.5-13A1.25 1.25 0 0 1 9.5 2m1.922 9L9.5 6.458L7.58 11zm9.838.328a4.536 4.536 0 0 1 6.414 6.415l-9.418 9.417a4 4 0 0 1-1.73 1.018l-6.253 1.787a1 1 0 0 1-1.236-1.236l1.785-6.251a4 4 0 0 1 1.018-1.73z"
                                                ></path>
                                              </svg>
                                            </span>
                                          </Whisper>
                                        </div>
                                      </aside>
                                    )}
                                    <span
                                      className={Styles.FileShare}
                                      onClick={() => {
                                        setShowFileModal(true);

                                        setAllMessage("");
                                      }}
                                    >
                                      <ImAttachment />
                                    </span>
                                    <Button
                                      appearance="primary"
                                      onClick={handleSend}
                                      className={Styles.MsgSendBtn}
                                    >
                                      Send
                                    </Button>
                                  </>
                                )}
                              </>
                            )}
                          </form>
                        </Box>
                      )}
                    </div>
                  </Modal.Body>
                </Modal>
              </div>
            </>
          )}

          {batchData?.length === 0 ? (
            <div
              className={Styles.MessageError1}
              style={{
                width: "100%",
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <h4 style={{ color: "#f16f1d" }}>
                You are not assigned to any Batch
              </h4>
            </div>
          ) : searchdata === false ? (
            currentItems
              ?.sort(
                (a, b) =>
                  new Date(b?.date).getTime() - new Date(a?.date).getTime()
              )
              ?.filter(val => val?.batchCode?.toLowerCase().includes(search1))
              ?.map((val, index) => {
                return (
                  <Fragment key={index + 1}>
                    <UserBatchListDetails {...val} Handlepage={Handlepage} />
                  </Fragment>
                );
              })
          ) : (
            currentItems
              ?.sort(
                (a, b) =>
                  new Date(b?.date).getTime() - new Date(a?.date).getTime()
              )
              ?.filter(val =>
                val?.batchCode?.toLowerCase()?.includes(search1?.toLowerCase())
              )
              ?.map((val, index) => {
                return (
                  <Fragment key={index + 1}>
                    <UserBatchListDetails {...val} />
                  </Fragment>
                );
              })
          )}
        </article>
        {totalPages > 1 ? (
          <div id={Styles.reactpaginate}>
            <ReactPaginate
              breakLabel="..."
              nextLabel="next >"
              pageRangeDisplayed={3}
              previousLabel="< previous"
              pageCount={totalPages}
              onPageChange={handlePageClick}
              // onPageChange={({ selected }) => setCurrentPage(selected)}
              containerClassName={"pagination"}
              subContainerClassName={"pages pagination"}
              activeClassName={"active"}
              forcePage={currentPage}
            />
          </div>
        ) : (
          ""
        )}
      </section>
    </>
  );
};

export default UserBatchData;
