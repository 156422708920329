import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useRef } from "react";
import AxiosInstance, { BddtAxios } from "../../api/AxiosInstance";
import Styles from "./_UserToUser.module.css";
import { socket } from "../../ioContext/socketIo";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Modal as MUIModal,
  Button as MUIButton,
  Divider,
  TextField,
  Grid,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Badge,
  Button,
} from "@mui/material";
import { toast } from "react-toastify";
import StudentHelpChatCompNew from "./StudentHelpChatCompNew";
import StudentHelpChatOld from "./StudentHelpChatOld";
import StudentHelpViewPreviousChat from "./StudentHelpViewPreviousChat";
import handleError from "../Users/ErrorHandler";
import Menu from "@mui/material/Menu";
import MenuItemm from "@mui/material/MenuItem";
const styleModal1 = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",

  width: {
    xs: "90%", // 0px to 599px
    sm: "50%", // 600px to 899px
    md: "35%", // 900px to 1199px
    lg: "28%", // 1200px and up
  },
  height: "auto",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 2,
  borderRadius: "10px",
  outline: "1px solid #f57c00",
  outlineOffset: "3px",
};

const ItemHeight = 46;
const itemPaddingTop = 8;
const MenuProps = {
  PaperProps: {
    style: { maxHeight: ItemHeight * 4.5 + itemPaddingTop, width: "250" },
  },
};

const StudentHelp = () => {
  let scrollRef = useRef();
  let navigate = useNavigate();

  const user1 = sessionStorage.getItem("user");
  const user = JSON.parse(user1);
  const userToken = sessionStorage.getItem("token");

  // modal data starts here
  const [openModal1, setOpenModal1] = React.useState(true);
  const [openModalExist, setModalExist] = useState(false);
  const [openModalExistEsc, setModalExistEsc] = useState(false);

  const [existingTitleList, setExistingTitleList] = useState([]);
  const [existingTitleListEsc, setExistingTitleListEsc] = useState([]);

  const [errorMessageList, setErrorMessageList] = useState("");
  const [loadExisting, setLoadExisting] = useState(false);
  const [viewChatComp, setViewChatComp] = useState(false);
  const [selectedTitle, setSelectedTitle] = useState(null);
  const [selectedOp, setSelectedOp] = useState(null);

  const [newTicketMsg, setNewTicketMsg] = useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const fetchExistingList = async () => {
    try {
      setLoadExisting(true);
      const { data } = await AxiosInstance.get(`/help/getTitle?search=`, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      });
      setLoadExisting(false);
      if (data.message) {
        setExistingTitleList([]);
        setErrorMessageList("No existing ticket");
      } else {
        const messageEscalation = [];
        const otherSections = [];

        // Categorize data in one iteration
        data?.data.forEach(item => {
          if (item.section === "message escalation") {
            messageEscalation.push(item);
          } else {
            otherSections.push(item);
          }
        });

        setExistingTitleListEsc(messageEscalation);
        setExistingTitleList(otherSections);
      }
    } catch (error) {
      if (error?.response?.status === 403) {
        handleError(navigate, socket);
      }
      setLoadExisting(null);
      console.log(error);
    }
  };

  // modal data ends here
  // ? state for creating new ticket
  const [newTicket, setNewTicket] = useState({
    title: "",
    subject: "",
    message: "",
    subject1: "",
  });
  const [selectedNewTicket, setSelectedNewTicket] = useState(null);

  const [searchTicket, setSearchTicket] = useState("");
  const [isSearchTrue, setIsSearchTrue] = useState(false);

  //Reply
  const [reply, setReply] = useState(false);
  const [selectedMessages, setSelectedMessages] = useState([]);

  const handleMessageClick = (clickedMessage, index, isMessageSelected) => {
    const existingIndex = selectedMessages.findIndex(
      msg =>
        (msg.messages.text === clickedMessage.messages.text &&
          msg.index === clickedMessage.index) ||
        (msg.messages.flies === clickedMessage?.messages?.flies &&
          msg.index === clickedMessage.index)
    );

    const isMessageAlreadySelected = existingIndex !== -1;
    const hasReachedMaxSelection = selectedMessages.length === 5;

    if (!isMessageAlreadySelected && selectedMessages.length < 5) {
      setSelectedMessages([...selectedMessages, clickedMessage]);
    } else if (isMessageAlreadySelected) {
      const updatedMessages = selectedMessages.filter(
        (_, index) => index !== existingIndex
      );
      setSelectedMessages(updatedMessages);
    } else if (selectedMessages.length === 5) {
      // Alert message when the selection limit is reached or duplicate is clicked
      alert(`You can select only 5 unique messages.`);
    }
  };
  const handleSearchTicketTitle = async () => {
    if (searchTicket.trim() === "") {
      toast.warn("Please enter a ticket title to search");
    } else {
      try {
        setIsSearchTrue(true);
        const response = await AxiosInstance.get(
          `/help/getTitle?search=${searchTicket}`,
          {
            headers: {
              Authorization: `Bearer ${userToken}`,
            },
          }
        );
        let data = response?.data?.data;
        setExistingTitleList(response.data.data);
      } catch (error) {
        if (error?.response?.status === 403) {
          handleError(navigate, socket);
        }
        setIsSearchTrue(false);
      }
    }
  };

  const handleRemoveSearchTicketTitle = async () => {
    setIsSearchTrue(false);
    setSearchTicket("");
    fetchExistingList();
  };

  const handleKeyPressUser = e => {
    if (e.key === "Enter") {
      if (searchTicket.trim() !== "") {
        // Trigger search if input is not empty
        handleSearchTicketTitle();
      }
    }
  };
  const handleBackspaceUser = event => {
    if (event.key === "Backspace" && searchTicket.length === 1) {
      setIsSearchTrue(false);
      setSearchTicket("");
      fetchExistingList();
    }
  };
  function stopPropagation(e) {
    e.stopPropagation();
  }
  const handleChangeCreateTicket = e => {
    const { name, value } = e.target;
    setNewTicket({ ...newTicket, [name]: value });
  };

  let [message, setMessage] = useState([]);
  const [openHelp, setOpenHelp] = React.useState(false);
  const [openHelpEsc, setOpenHelpEsc] = React.useState(false);

  const [AllSubjectList, setAllSubjectList] = useState([]);

  useEffect(() => {
    scrollRef.current?.scrollIntoView({
      behavior: "auto",
      block: "end",
      inline: "nearest",
    });
  }, [message]);

  const fetchSubject = async () => {
    try {
      const { data } = await BddtAxios.get(
        `/courses/subject/?stu_token=${userToken}`
      );
      setAllSubjectList(data.results);
    } catch (error) {
      if (error?.response?.status === 403) {
        handleError(navigate, socket);
      }
    }
  };

  const handleCreateNewTicket = async e => {
    if (newTicket.subject === "") {
      toast.warn("Please select section");
    } else if (
      newTicket.subject === "course related queries" &&
      newTicket.subject1 === ""
    ) {
      toast.warn("Please select subject");
    } else if (newTicket.title.trim() === "") {
      toast.warn("Please enter title");
    } else if (newTicket.message.trim() === "") {
      toast.warn("Please enter message");
    } else if (
      existingTitleList?.some(ticket => ticket.title === newTicket.title)
    ) {
      toast.warn("Title already exists");
    } else {
      socket.emit(
        "helpGroup",
        {
          senderId: user?._id,
          senderName: user.username,
          text: newTicket.message,
          role: user?.role1,
          title: newTicket.title,
          section: newTicket.subject,
          requestId: null,
          subject:
            newTicket.subject === "course related queries"
              ? newTicket.subject1
              : null,
        },
        function callback(msg) {
          setMessage([msg]);
          setNewTicketMsg([msg]);
          setViewChatComp(true);
          setSelectedOp("new");

          if (msg.read === false) {
            msg.tick = false;
            // setMessage([...message, msg]);
          } else {
            // setMessage([...message, msg]);
          }
        }
      );
      setOpenHelp(false);
      setSelectedNewTicket(newTicket);
      toast.success("Ticket created successfully");
      setNewTicket({
        title: "",
        message: "",
        subject1: "",
        subject: "",
      });
    }
  };

  const handleCreateNewTicketEsc = async e => {
    if (newTicket.title.trim() === "") {
      toast.warn("Please enter title");
    } else if (newTicket.message.trim() === "") {
      toast.warn("Please enter message");
    } else if (
      existingTitleListEsc?.some(ticket => ticket.title === newTicket.title)
    ) {
      toast.warn("Title already exists");
    } else {
      socket.emit(
        "helpGroup",
        {
          senderId: user?._id,
          senderName: user.username,
          text: newTicket.message,
          role: user?.role1,
          title: newTicket.title,
          section: "message escalation",
          requestId: null,
          subject:
            newTicket.subject === "course related queries"
              ? newTicket.subject1
              : null,
        },
        function callback(msg) {
          setMessage([msg]);
          setNewTicketMsg([msg]);
          setViewChatComp(true);
          setSelectedOp("new");

          if (msg.read === false) {
            msg.tick = false;
            // setMessage([...message, msg]);
          } else {
            // setMessage([...message, msg]);
          }
        }
      );
      setOpenHelpEsc(false);
      setSelectedNewTicket(newTicket);
      toast.success("Ticket created successfully");
      setNewTicket({
        title: "",
        message: "",
        subject1: "",
        subject: "",
      });
    }
  };

  let HandleCloseModal = () => {
    navigate(-1);
  };
  const [selectRequestId, setSelectRequestId] = useState(null);
  const [showOldChats, setShowOldChats] = useState(false);
  const [isDescriptionOpen, setIsDescriptionOpen] = useState(false);

  const handleClickViewDescription = item => {
    // fetchUsers();
    setAnchorEl(null);
    setShowOldChats(false);
    setIsDescriptionOpen(true);
  };
  const handleCloseIssue = async () => {
    try {
      let payload = {
        type_action: selectRequestId.closed === true ? "open" : "close",
        studentId: user?._id,
        requestId: selectRequestId?.requestId,
      };
      const { data } = await AxiosInstance.patch(
        `/help/close_open_helpgroup`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${user.TOKEN}`,
          },
        }
      );
      selectRequestId.closed === false
        ? toast.success("Issue is closed")
        : toast.success("Issue is opened");

      setIsDescriptionOpen(false);
      setSelectRequestId(null);
      fetchExistingList();
      // toast.success("Issue is closed");
    } catch (error) {
      console.log(error);
      setSelectRequestId(null);
    }
  };
  return (
    <div className={Styles.chatarea}>
      <div className={Styles.NameBlock}>
        <aside className={Styles.HeaderHelp}>
          <MUIModal
            open={isDescriptionOpen}
            onClose={() => {
              setIsDescriptionOpen(false);
            }}
          >
            <Box sx={styleModal1} position="relative">
              <button
                style={{
                  background: "transparent",
                  position: "absolute",
                  right: "5px",
                  top: "5px",
                }}
                onClick={() => {
                  setIsDescriptionOpen(false);
                }}
              >
                <svg width={18.54} height={20} viewBox="0 0 1216 1312">
                  <path
                    fill="crimson"
                    d="M1202 1066q0 40-28 68l-136 136q-28 28-68 28t-68-28L608 976l-294 294q-28 28-68 28t-68-28L42 1134q-28-28-28-68t28-68l294-294L42 410q-28-28-28-68t28-68l136-136q28-28 68-28t68 28l294 294l294-294q28-28 68-28t68 28l136 136q28 28 28 68t-28 68L880 704l294 294q28 28 28 68"
                  ></path>
                </svg>
              </button>
              <h4 style={{ textAlign: "center", color: "#f16f1d" }}>
                Do you want to close the issue
              </h4>
              <Divider />
              <br />
              <Grid
                item
                sm={12}
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-evenly",
                }}
              >
                <Button
                  type="button"
                  size="small"
                  variant="outlined"
                  color="warning"
                  onClick={() => {
                    setIsDescriptionOpen(false);
                    setSelectRequestId(null);
                    // setSelectedBranch(null);
                    // setOpenAddUser(false);
                    // setSelectedUsers([]);
                    // setSelectedBranch(null);
                  }}
                >
                  No
                </Button>
                <Button
                  type="submit"
                  size="small"
                  variant="contained"
                  color="warning"
                  onClick={() => handleCloseIssue()}
                >
                  Yes
                </Button>
              </Grid>
              <br />
            </Box>
          </MUIModal>
          <MUIModal open={openModal1} onClose={HandleCloseModal}>
            <Box sx={styleModal1} position="relative">
              <button
                style={{
                  background: "transparent",
                  position: "absolute",
                  right: "5px",
                  top: "5px",
                }}
                onClick={() => {
                  navigate(-1);
                }}
                title="Close"
              >
                <svg width={18.54} height={20} viewBox="0 0 1216 1312">
                  <path
                    fill="crimson"
                    d="M1202 1066q0 40-28 68l-136 136q-28 28-68 28t-68-28L608 976l-294 294q-28 28-68 28t-68-28L42 1134q-28-28-28-68t28-68l294-294L42 410q-28-28-28-68t28-68l136-136q28-28 68-28t68 28l294 294l294-294q28-28 68-28t68 28l136 136q28 28 28 68t-28 68L880 704l294 294q28 28 28 68"
                  ></path>
                </svg>
              </button>
              <h4 style={{ textAlign: "center", color: "#f16f1d" }}>
                Help/Ticket
              </h4>
              <Divider />
              <br />
              <div style={{ display: "flex", justifyContent: "space-evenly" }}>
                <MUIButton
                  variant="contained"
                  color="info"
                  size="small"
                  type="button"
                  onClick={() => {
                    setOpenModal1(false);
                    setOpenHelp(true);
                    fetchExistingList();
                    setViewChatComp(false);
                  }}
                >
                  <svg width={20} height={20} viewBox="0 0 24 24">
                    <path
                      fill="#fff"
                      d="M21 14v5a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h5v2H5v14h14v-5z"
                    ></path>
                    <path
                      fill="#fff"
                      d="M21 7h-4V3h-2v4h-4v2h4v4h2V9h4z"
                    ></path>
                  </svg>
                  &nbsp; New
                </MUIButton>
                {/* <MUIButton
                  variant="contained"
                  color="info"
                  size="small"
                  type="button"
                  onClick={() => {
                    setOpenModal1(false);
                    setOpenHelpEsc(true);
                    fetchExistingList();
                    setViewChatComp(false);
                  }}
                >
                  <svg width={20} height={20} viewBox="0 0 24 24">
                    <path
                      fill="#fff"
                      d="M21 14v5a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h5v2H5v14h14v-5z"
                    ></path>
                    <path
                      fill="#fff"
                      d="M21 7h-4V3h-2v4h-4v2h4v4h2V9h4z"
                    ></path>
                  </svg>
                  &nbsp; Escalation
                </MUIButton> */}
                <MUIButton
                  variant="outlined"
                  color="info"
                  size="small"
                  type="button"
                  onClick={() => {
                    setOpenModal1(false);
                    fetchExistingList();
                    setModalExist(true);
                    setViewChatComp(false);
                  }}
                >
                  <svg width={20} height={20} viewBox="0 0 20 20">
                    <path
                      fill="#0288d1"
                      d="M13 0a2 2 0 0 1 2 2H6a2 2 0 0 0-2 2v12a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2z"
                    ></path>
                    <path
                      fill="#0288d1"
                      d="M5 18c0 1.1.9 2 2 2h9c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2v9l-2.8-2.8l-2.8 2.8V3H7C5.4 3 5 4.6 5 5z"
                    ></path>
                  </svg>
                  &nbsp; Existing
                </MUIButton>
              </div>
            </Box>
          </MUIModal>
          {/* modal for new ticket */}
          <MUIModal open={openHelp}>
            <Box sx={styleModal1}>
              <h4 style={{ textAlign: "center", color: "#f16f1d" }}>
                Create New Ticket
              </h4>
              <Divider />
              <br />
              <Grid container spacing={2}>
                <Grid item sm={12} xs={12}>
                  <FormControl fullWidth>
                    <InputLabel size="small" color="warning">
                      Select Section *
                    </InputLabel>
                    <Select
                      MenuProps={MenuProps}
                      required
                      size="small"
                      value={newTicket.subject}
                      label="Select Section"
                      color="warning"
                      name="subject"
                      onChange={e => {
                        handleChangeCreateTicket(e);
                      }}
                    >
                      <MenuItem value="general queries">
                        General Queries
                      </MenuItem>
                      <MenuItem value="request profile update">
                        Request Profile Update
                      </MenuItem>
                      <MenuItem value="course related queries">
                        Course Related Queries
                      </MenuItem>
                      <MenuItem value="hr queries">HR Queries</MenuItem>
                      <MenuItem value="technical doubt clearance">
                        Technical Doubt Clearance
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                {newTicket.subject === "course related queries" ? (
                  <Grid item sm={12} xs={12}>
                    <FormControl fullWidth>
                      <InputLabel size="small" color="warning">
                        Select Subject *
                      </InputLabel>
                      <Select
                        MenuProps={MenuProps}
                        onOpen={fetchSubject}
                        required
                        size="small"
                        value={newTicket.subject1}
                        label="Select subject"
                        color="warning"
                        name="subject1"
                        onChange={e => {
                          handleChangeCreateTicket(e);
                        }}
                      >
                        {AllSubjectList.map((item, index) => {
                          return (
                            <MenuItem value={item.name} key={index}>
                              {item.name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Grid>
                ) : null}

                <Grid item sm={12} xs={12}>
                  <TextField
                    required
                    label="Ticket Title"
                    variant="outlined"
                    size="small"
                    fullWidth
                    color="warning"
                    value={newTicket.title}
                    name="title"
                    onChange={e => {
                      handleChangeCreateTicket(e);
                    }}
                  />
                </Grid>
                <Grid item sm={12} xs={12}>
                  <TextField
                    multiline
                    minRows={2}
                    label="Enter Message"
                    required
                    variant="outlined"
                    size="small"
                    fullWidth
                    color="warning"
                    value={newTicket.message}
                    name="message"
                    onChange={e => {
                      handleChangeCreateTicket(e);
                    }}
                  />
                </Grid>
                <Grid
                  item
                  sm={12}
                  xs={12}
                  style={{ display: "flex", justifyContent: "space-evenly" }}
                >
                  <MUIButton
                    variant="outlined"
                    color="info"
                    size="small"
                    onClick={() => {
                      setOpenHelp(false);
                      setOpenModal1(true);
                      setNewTicket({
                        title: "",
                        message: "",
                        subject: "",
                        subject1: "",
                      });
                    }}
                  >
                    Cancel
                  </MUIButton>
                  <MUIButton
                    variant="contained"
                    color="warning"
                    size="small"
                    onClick={() => {
                      handleCreateNewTicket();
                    }}
                  >
                    Submit
                  </MUIButton>
                </Grid>
              </Grid>
            </Box>
          </MUIModal>

          {/* modal for Escalation ticket */}
          <MUIModal open={openHelpEsc}>
            <Box sx={styleModal1}>
              <h4 style={{ textAlign: "center", color: "#f16f1d" }}>
                Create New Escalation Ticket
              </h4>
              <Divider />
              <br />
              <Grid container spacing={2}>
                {/* <Grid item sm={12} xs={12}>
                  <FormControl fullWidth>
                    <InputLabel size="small" color="warning">
                      Select Section *
                    </InputLabel>
                    <Select
                      MenuProps={MenuProps}
                      required
                      size="small"
                      value={newTicket.subject}
                      label="Select Section"
                      color="warning"
                      name="subject"
                      onChange={e => {
                        handleChangeCreateTicket(e);
                      }}
                    >
                      <MenuItem value="general queries">
                        General Queries
                      </MenuItem>
                      <MenuItem value="request profile update">
                        Request Profile Update
                      </MenuItem>
                      <MenuItem value="course related queries">
                        Course Related Queries
                      </MenuItem>
                      <MenuItem value="hr queries">HR Queries</MenuItem>
                      <MenuItem value="technical doubt clearance">
                        Technical Doubt Clearance
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid> */}
                {newTicket.subject === "course related queries" ? (
                  <Grid item sm={12} xs={12}>
                    <FormControl fullWidth>
                      <InputLabel size="small" color="warning">
                        Select Subject *
                      </InputLabel>
                      <Select
                        MenuProps={MenuProps}
                        onOpen={fetchSubject}
                        required
                        size="small"
                        value={newTicket.subject1}
                        label="Select subject"
                        color="warning"
                        name="subject1"
                        onChange={e => {
                          handleChangeCreateTicket(e);
                        }}
                      >
                        {AllSubjectList.map((item, index) => {
                          return (
                            <MenuItem value={item.name} key={index}>
                              {item.name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Grid>
                ) : null}

                <Grid item sm={12} xs={12}>
                  <TextField
                    required
                    label="Ticket Title"
                    variant="outlined"
                    size="small"
                    fullWidth
                    color="warning"
                    value={newTicket.title}
                    name="title"
                    onChange={e => {
                      handleChangeCreateTicket(e);
                    }}
                  />
                </Grid>
                <Grid item sm={12} xs={12}>
                  <TextField
                    multiline
                    minRows={2}
                    label="Enter Message"
                    required
                    variant="outlined"
                    size="small"
                    fullWidth
                    color="warning"
                    value={newTicket.message}
                    name="message"
                    onChange={e => {
                      handleChangeCreateTicket(e);
                    }}
                  />
                </Grid>
                <Grid
                  item
                  sm={12}
                  xs={12}
                  style={{ display: "flex", justifyContent: "space-evenly" }}
                >
                  <MUIButton
                    variant="outlined"
                    color="info"
                    size="small"
                    onClick={() => {
                      setOpenHelpEsc(false);
                      setOpenModal1(true);
                      setNewTicket({
                        title: "",
                        message: "",
                        subject: "",
                        subject1: "",
                      });
                    }}
                  >
                    Cancel
                  </MUIButton>
                  <MUIButton
                    variant="contained"
                    color="warning"
                    size="small"
                    onClick={() => {
                      handleCreateNewTicketEsc();
                    }}
                  >
                    Submit
                  </MUIButton>
                </Grid>
              </Grid>
            </Box>
          </MUIModal>

          {/* modal for existing ticket */}
          <MUIModal open={openModalExist}>
            <Box sx={styleModal1} position="relative">
              <h4 style={{ textAlign: "center", color: "#f16f1d" }}>
                Ticket List
              </h4>
              <Divider />
              <br />
              <button
                style={{
                  border: "none",
                  background: "none",
                  position: "absolute",
                  right: "5px",
                  top: "5px",
                }}
                onClick={() => {
                  setModalExist(false);
                  setOpenModal1(true);
                  setSelectRequestId(null);
                }}
              >
                <svg width={18.54} height={20} viewBox="0 0 1216 1312">
                  <path
                    fill="crimson"
                    d="M1202 1066q0 40-28 68l-136 136q-28 28-68 28t-68-28L608 976l-294 294q-28 28-68 28t-68-28L42 1134q-28-28-28-68t28-68l294-294L42 410q-28-28-28-68t28-68l136-136q28-28 68-28t68 28l294 294l294-294q28-28 68-28t68 28l136 136q28 28 28 68t-28 68L880 704l294 294q28 28 28 68"
                  ></path>
                </svg>
              </button>
              {/* search text box */}

              {errorMessageList === "" && (
                <div className={Styles.existingListSearchField}>
                  <input
                    placeholder="Search Ticket Title"
                    value={searchTicket}
                    onChange={e => {
                      setSearchTicket(e.target.value);
                    }}
                    onKeyPress={handleKeyPressUser}
                    onKeyDown={handleBackspaceUser}
                  />
                  {!isSearchTrue ? (
                    <button
                      onClick={handleSearchTicketTitle}
                      title="click to search"
                    >
                      <svg
                        width={20}
                        height={20}
                        viewBox="0 0 24 24"
                        strokeWidth="4"
                      >
                        <path
                          fill="#f16f1d"
                          d="M15.5 14h-.79l-.28-.27A6.471 6.471 0 0 0 16 9.5A6.5 6.5 0 1 0 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5S14 7.01 14 9.5S11.99 14 9.5 14"
                        ></path>
                      </svg>
                    </button>
                  ) : (
                    <button
                      onClick={handleRemoveSearchTicketTitle}
                      title="click to remove search"
                    >
                      <svg
                        width={20}
                        height={20}
                        viewBox="0 0 24 24"
                        strokeWidth="4"
                      >
                        <path
                          fill="crimson"
                          d="M12 16c1.671 0 3-1.331 3-3s-1.329-3-3-3s-3 1.331-3 3s1.329 3 3 3"
                        ></path>
                        <path
                          fill="crimson"
                          d="M20.817 11.186a8.94 8.94 0 0 0-1.355-3.219a9.053 9.053 0 0 0-2.43-2.43a8.95 8.95 0 0 0-3.219-1.355a9.028 9.028 0 0 0-1.838-.18V2L8 5l3.975 3V6.002c.484-.002.968.044 1.435.14a6.961 6.961 0 0 1 2.502 1.053a7.005 7.005 0 0 1 1.892 1.892A6.967 6.967 0 0 1 19 13a7.032 7.032 0 0 1-.55 2.725a7.11 7.11 0 0 1-.644 1.188a7.2 7.2 0 0 1-.858 1.039a7.028 7.028 0 0 1-3.536 1.907a7.13 7.13 0 0 1-2.822 0a6.961 6.961 0 0 1-2.503-1.054a7.002 7.002 0 0 1-1.89-1.89A6.996 6.996 0 0 1 5 13H3a9.02 9.02 0 0 0 1.539 5.034a9.096 9.096 0 0 0 2.428 2.428A8.95 8.95 0 0 0 12 22a9.09 9.09 0 0 0 1.814-.183a9.014 9.014 0 0 0 3.218-1.355a8.886 8.886 0 0 0 1.331-1.099a9.228 9.228 0 0 0 1.1-1.332A8.952 8.952 0 0 0 21 13a9.09 9.09 0 0 0-.183-1.814"
                        ></path>
                      </svg>
                    </button>
                  )}
                </div>
              )}

              <div>
                {loadExisting === null ? (
                  <h4 style={{ textAlign: "center" }}>something went wrong</h4>
                ) : loadExisting ? (
                  <h5 style={{ textAlign: "center", color: "#f57c00" }}>
                    Loading...
                  </h5>
                ) : errorMessageList !== "" ? (
                  <h4 className={Styles.errorMessageExistingList}>
                    {errorMessageList}
                  </h4>
                ) : (
                  <div className={Styles.existingListUl}>
                    <ul>
                      <li
                        onClick={() => {
                          setViewChatComp(true);
                          setSelectedOp("previous");
                          setModalExist(false);
                        }}
                      >
                        Old General Queries
                      </li>
                      <li
                        onClick={() => {
                          setModalExistEsc(true);
                          setSelectedOp("previous");
                          setModalExist(false);
                        }}
                      >
                        Escalated messages
                      </li>
                      {existingTitleList.length < 1 ? (
                        <h4 className={Styles.errorMessageExistingList}>
                          No Ticket Found
                        </h4>
                      ) : (
                        !loadExisting &&
                        existingTitleList
                          ?.sort((a, b) => a.closed - b.closed)
                          ?.map((item, ind) => {
                            return (
                              <li
                                key={ind + 1}
                                onClick={() => {
                                  setViewChatComp(true);
                                  setSelectedTitle(item);
                                  setModalExist(false);
                                  setSelectedOp("old");
                                }}
                                style={{ position: "relative" }}
                              >
                                {item?.closed === true && (
                                  <p
                                    style={{
                                      color: "lightslategray",
                                      fontSize: "11px",
                                      textTransform: "capitalize",
                                    }}
                                  >
                                    Issue is{" "}
                                    <span style={{ color: "red" }}>Closed</span>
                                  </p>
                                )}
                                <p
                                  style={{
                                    color: "gray",
                                    fontSize: "12px",
                                    textTransform: "capitalize",
                                  }}
                                >
                                  {item.section}
                                </p>
                                {item.title}{" "}
                                {item.conversation.length > 0 &&
                                  item.conversation[0].notification && (
                                    <span
                                      style={{
                                        position: "absolute",
                                        right: "12%",
                                      }}
                                    >
                                      <Badge
                                        style={{ fontSize: "small" }}
                                        badgeContent={
                                          item.conversation[0].noticount
                                        }
                                        color="success"
                                      ></Badge>
                                    </span>
                                  )}
                                <Button
                                  id="basic-button"
                                  aria-controls={
                                    open ? "basic-menu" : undefined
                                  }
                                  aria-haspopup="true"
                                  aria-expanded={open ? "true" : undefined}
                                  sx={{
                                    float: "right",
                                    background: "transparent",
                                    maxWidth: "10px",
                                    padding: 0,
                                    minWidth: 30,
                                  }}
                                  onClick={e => {
                                    handleClick(e);
                                    setSelectRequestId(item);
                                    e.stopPropagation();
                                  }}
                                >
                                  <svg
                                    width="18"
                                    height="18"
                                    viewBox="0 0 16 16"
                                  >
                                    <g
                                      fill="none"
                                      stroke="#f16f1d"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      strokeWidth="3"
                                    >
                                      <circle cx="8" cy="2.5" r=".75" />
                                      <circle cx="8" cy="8" r=".75" />
                                      <circle cx="8" cy="13.5" r=".75" />
                                    </g>
                                  </svg>
                                </Button>
                                <Menu
                                  id="basic-menu"
                                  anchorEl={anchorEl}
                                  open={open}
                                  onClose={handleClose}
                                  MenuListProps={{
                                    "aria-labelledby": "basic-button",
                                    sx: {
                                      padding: 0,
                                    },
                                  }}
                                  PaperProps={{
                                    sx: {
                                      boxShadow:
                                        "0px 2px 4px rgba(0, 0, 0, 0.1)",
                                    },
                                  }}
                                >
                                  {selectRequestId?.closed === false ? (
                                    <MenuItem
                                      onClick={e => {
                                        e.stopPropagation();
                                        handleClickViewDescription();
                                      }}
                                    >
                                      Close
                                    </MenuItem>
                                  ) : (
                                    <MenuItem
                                      onClick={e => {
                                        e.stopPropagation();
                                        handleClickViewDescription();
                                      }}
                                    >
                                      Open
                                    </MenuItem>
                                  )}
                                </Menu>
                              </li>
                            );
                          })
                      )}
                    </ul>
                  </div>
                )}
              </div>
            </Box>
          </MUIModal>
          {/* modal for existing Escalated ticket */}
          <MUIModal open={openModalExistEsc}>
            <Box sx={styleModal1} position="relative">
              <h4 style={{ textAlign: "center", color: "#f16f1d" }}>
                Escalated Ticket List
              </h4>
              <Divider />
              <br />
              <button
                style={{
                  border: "none",
                  background: "none",
                  position: "absolute",
                  right: "5px",
                  top: "5px",
                }}
                onClick={() => {
                  setModalExistEsc(false);
                  setOpenModal1(true);
                  setSelectRequestId(null);
                }}
              >
                <svg width={18.54} height={20} viewBox="0 0 1216 1312">
                  <path
                    fill="crimson"
                    d="M1202 1066q0 40-28 68l-136 136q-28 28-68 28t-68-28L608 976l-294 294q-28 28-68 28t-68-28L42 1134q-28-28-28-68t28-68l294-294L42 410q-28-28-28-68t28-68l136-136q28-28 68-28t68 28l294 294l294-294q28-28 68-28t68 28l136 136q28 28 28 68t-28 68L880 704l294 294q28 28 28 68"
                  ></path>
                </svg>
              </button>
              {/* search text box */}

              {errorMessageList === "" && (
                <div className={Styles.existingListSearchField}>
                  <input
                    placeholder="Search Ticket Title"
                    value={searchTicket}
                    onChange={e => {
                      setSearchTicket(e.target.value);
                    }}
                    onKeyPress={handleKeyPressUser}
                    onKeyDown={handleBackspaceUser}
                  />
                  {!isSearchTrue ? (
                    <button
                      onClick={handleSearchTicketTitle}
                      title="click to search"
                    >
                      <svg
                        width={20}
                        height={20}
                        viewBox="0 0 24 24"
                        strokeWidth="4"
                      >
                        <path
                          fill="#f16f1d"
                          d="M15.5 14h-.79l-.28-.27A6.471 6.471 0 0 0 16 9.5A6.5 6.5 0 1 0 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5S14 7.01 14 9.5S11.99 14 9.5 14"
                        ></path>
                      </svg>
                    </button>
                  ) : (
                    <button
                      onClick={handleRemoveSearchTicketTitle}
                      title="click to remove search"
                    >
                      <svg
                        width={20}
                        height={20}
                        viewBox="0 0 24 24"
                        strokeWidth="4"
                      >
                        <path
                          fill="crimson"
                          d="M12 16c1.671 0 3-1.331 3-3s-1.329-3-3-3s-3 1.331-3 3s1.329 3 3 3"
                        ></path>
                        <path
                          fill="crimson"
                          d="M20.817 11.186a8.94 8.94 0 0 0-1.355-3.219a9.053 9.053 0 0 0-2.43-2.43a8.95 8.95 0 0 0-3.219-1.355a9.028 9.028 0 0 0-1.838-.18V2L8 5l3.975 3V6.002c.484-.002.968.044 1.435.14a6.961 6.961 0 0 1 2.502 1.053a7.005 7.005 0 0 1 1.892 1.892A6.967 6.967 0 0 1 19 13a7.032 7.032 0 0 1-.55 2.725a7.11 7.11 0 0 1-.644 1.188a7.2 7.2 0 0 1-.858 1.039a7.028 7.028 0 0 1-3.536 1.907a7.13 7.13 0 0 1-2.822 0a6.961 6.961 0 0 1-2.503-1.054a7.002 7.002 0 0 1-1.89-1.89A6.996 6.996 0 0 1 5 13H3a9.02 9.02 0 0 0 1.539 5.034a9.096 9.096 0 0 0 2.428 2.428A8.95 8.95 0 0 0 12 22a9.09 9.09 0 0 0 1.814-.183a9.014 9.014 0 0 0 3.218-1.355a8.886 8.886 0 0 0 1.331-1.099a9.228 9.228 0 0 0 1.1-1.332A8.952 8.952 0 0 0 21 13a9.09 9.09 0 0 0-.183-1.814"
                        ></path>
                      </svg>
                    </button>
                  )}
                </div>
              )}

              <div>
                {loadExisting === null ? (
                  <h4 style={{ textAlign: "center" }}>something went wrong</h4>
                ) : loadExisting ? (
                  <h5 style={{ textAlign: "center", color: "#f57c00" }}>
                    Loading...
                  </h5>
                ) : errorMessageList !== "" ? (
                  <h4 className={Styles.errorMessageExistingList}>
                    {errorMessageList}
                  </h4>
                ) : (
                  <div className={Styles.existingListUl}>
                    <ul>
                      {/* <li
                        onClick={() => {
                          setViewChatComp(true);
                          setSelectedOp("previous");
                          setModalExist(false);
                        }}
                      >
                        Old General Queries
                      </li>
                      <li
                        onClick={() => {
                          setModalExistEsc(true);
                          setSelectedOp("previous");
                          setModalExist(false);
                        }}
                      >
                        Escalated messages
                      </li> */}
                      {existingTitleListEsc.length < 1 ? (
                        <h4 className={Styles.errorMessageExistingList}>
                          No Ticket Found
                        </h4>
                      ) : (
                        !loadExisting &&
                        existingTitleListEsc
                          ?.sort((a, b) => a.closed - b.closed)
                          ?.map((item, ind) => {
                            return (
                              <li
                                key={ind + 1}
                                onClick={() => {
                                  setViewChatComp(true);
                                  setSelectedTitle(item);
                                  setModalExistEsc(false);
                                  setSelectedOp("old");
                                }}
                                style={{ position: "relative" }}
                              >
                                {item?.closed === true && (
                                  <p
                                    style={{
                                      color: "lightslategray",
                                      fontSize: "11px",
                                      textTransform: "capitalize",
                                    }}
                                  >
                                    Issue is{" "}
                                    <span style={{ color: "red" }}>Closed</span>
                                  </p>
                                )}
                                <p
                                  style={{
                                    color: "gray",
                                    fontSize: "12px",
                                    textTransform: "capitalize",
                                  }}
                                >
                                  {item.section}
                                </p>
                                {item.title}{" "}
                                {item.conversation.length > 0 &&
                                  item.conversation[0].notification && (
                                    <span
                                      style={{
                                        position: "absolute",
                                        right: "12%",
                                      }}
                                    >
                                      <Badge
                                        style={{ fontSize: "small" }}
                                        badgeContent={
                                          item.conversation[0].noticount
                                        }
                                        color="success"
                                      ></Badge>
                                    </span>
                                  )}
                                <Button
                                  id="basic-button"
                                  aria-controls={
                                    open ? "basic-menu" : undefined
                                  }
                                  aria-haspopup="true"
                                  aria-expanded={open ? "true" : undefined}
                                  sx={{
                                    float: "right",
                                    background: "transparent",
                                    maxWidth: "10px",
                                    padding: 0,
                                    minWidth: 30,
                                  }}
                                  onClick={e => {
                                    handleClick(e);
                                    setSelectRequestId(item);
                                    e.stopPropagation();
                                  }}
                                >
                                  <svg
                                    width="18"
                                    height="18"
                                    viewBox="0 0 16 16"
                                  >
                                    <g
                                      fill="none"
                                      stroke="#f16f1d"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      strokeWidth="3"
                                    >
                                      <circle cx="8" cy="2.5" r=".75" />
                                      <circle cx="8" cy="8" r=".75" />
                                      <circle cx="8" cy="13.5" r=".75" />
                                    </g>
                                  </svg>
                                </Button>
                                <Menu
                                  id="basic-menu"
                                  anchorEl={anchorEl}
                                  open={open}
                                  onClose={handleClose}
                                  MenuListProps={{
                                    "aria-labelledby": "basic-button",
                                    sx: {
                                      padding: 0,
                                    },
                                  }}
                                  PaperProps={{
                                    sx: {
                                      boxShadow:
                                        "0px 2px 4px rgba(0, 0, 0, 0.1)",
                                    },
                                  }}
                                >
                                  {selectRequestId?.closed === false ? (
                                    <MenuItem
                                      onClick={e => {
                                        e.stopPropagation();
                                        handleClickViewDescription();
                                      }}
                                    >
                                      Close
                                    </MenuItem>
                                  ) : (
                                    <MenuItem
                                      onClick={e => {
                                        e.stopPropagation();
                                        handleClickViewDescription();
                                      }}
                                    >
                                      Open
                                    </MenuItem>
                                  )}
                                </Menu>
                              </li>
                            );
                          })
                      )}
                    </ul>
                  </div>
                )}
              </div>
            </Box>
          </MUIModal>
        </aside>
        <>
          <div
            className={Styles.HEadBlockName}
            style={{ borderBottom: "1px solid gray" }}
          >
            <span
              className={Styles.HeadName}
              style={{ color: "#f16f1d", fontWeight: "bold" }}
            >
              Help &nbsp;
              <span
                className={Styles.ChangeUserIcon}
                title="Change Ticket"
                onClick={() => {
                  setOpenModal1(true);
                  setSelectedTitle(null);
                  setSelectedOp(null);
                  setViewChatComp(false);
                }}
                style={{ cursor: "pointer" }}
              >
                <svg width={24} height={24} viewBox="0 0 24 24">
                  <g
                    fill="none"
                    stroke="crimson"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={1.5}
                  >
                    <path d="M19.75 6.75h-12a4 4 0 0 0-4 4v2m16-1v2a4 4 0 0 1-4 4h-12"></path>
                    <path d="m16.75 9.75l3-3l-3-3m-10 11l-3 3l3 3"></path>
                  </g>
                </svg>{" "}
              </span>
            </span>
            <span className={Styles.ChangeUser}>
              {viewChatComp ? (
                <p className={Styles.General}>
                  {selectedOp === "new" ? (
                    <span style={{ fontSize: "large", fontWeight: "bold" }}>
                      {selectedNewTicket.title}
                      <small style={{ fontSize: "x-small" }}>
                        ({selectedNewTicket.subject})
                      </small>
                    </span>
                  ) : selectedOp === "old" ? (
                    <span style={{ fontSize: "large", fontWeight: "bold" }}>
                      {selectedTitle.title}
                      <small style={{ fontSize: "x-small" }}>
                        ({selectedTitle.section})
                      </small>
                    </span>
                  ) : selectedOp === "previous" ? (
                    "General Queries"
                  ) : null}
                </p>
              ) : null}
            </span>
            <span className={Styles.HeadDeatils}>
              <h6> Chats Are Monitored By Admin</h6>
            </span>
          </div>
          {viewChatComp ? (
            selectedOp === "new" ? (
              <StudentHelpChatCompNew
                messagesNew={message}
                ticketTitle={selectedNewTicket}
                setReply={setReply}
                reply={reply}
                selectedMessages={selectedMessages}
                onClick={handleMessageClick}
                setSelectedMessages={setSelectedMessages}
                newTicketMsg={newTicketMsg}
              />
            ) : selectedOp === "old" ? (
              <StudentHelpChatOld
                selectedTitle={selectedTitle}
                setReply={setReply}
                reply={reply}
                selectedMessages={selectedMessages}
                onClick={handleMessageClick}
                setSelectedMessages={setSelectedMessages}
              />
            ) : selectedOp === "previous" ? (
              <StudentHelpViewPreviousChat />
            ) : null
          ) : null}
        </>
      </div>
    </div>
  );
};

export default StudentHelp;
