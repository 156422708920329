import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  LinearProgress,
  Link,
  List,
  ListItem,
  ListItemText,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { socket } from "../../../ioContext/socketIo";
import handleError from "../../Users/ErrorHandler";
import AxiosInstance from "../../../api/AxiosInstance";
import { useNavigate } from "react-router-dom";
import fetchImageUrl from "../../functions/FetchFileWithToken";
import Linkify from "react-linkify";
import linkifyHtml from "linkify-html";
import useDownloader from "react-use-downloader";
import { toast } from "react-toastify";
import styles from "./_chat.module.css";
import Moment from "react-moment";

const UserFilter = ({ particularTrainer }) => {
  const user1 = sessionStorage.getItem("user");
  const user = JSON.parse(user1);
  const userToken = sessionStorage.getItem("token");
  let navigate = useNavigate();
  const [activeSection, setActiveSection] = useState("Search");
  const [searchText, setSearchText] = useState("");
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(false);
  const clearSearch = () => {
    setSearchText("");
    setMessages([]);
  };
  const { download, isInProgress } = useDownloader();
  const [previewImage, setPreviewImage] = useState("");

  function stopPropagation(e) {
    e.stopPropagation();
  }
  const openImagePreview = imageSrc => {
    setPreviewImage(imageSrc);
  };
  let handleImage = e => {
    // handleOpen(true);
    openImagePreview(e);
  };

  function linkifyHtmlContent(text) {
    // Process the text to convert URLs into clickable links
    const linkifyOptions = {
      defaultProtocol: "https",
      target: "_blank", // Open links in a new tab
      rel: "noopener noreferrer", // For security
    };

    return linkifyHtml(text, linkifyOptions);
  }

  //image url
  const [imageUrl, setImageUrl] = useState("");
  let [replyimgUrl, setReplyImageUrl] = useState("");
  // image url for  reply message
  useEffect(() => {
    const fetchUrls = async () => {
      const newFileUrls = {};

      await Promise.all(
        messages.map(async message => {
          if (
            message?.replayback &&
            Object.keys(message?.replayback).length > 0 &&
            message?.replayback?.file !== "" &&
            message?.replayback?.file != undefined
          ) {
            const fileData = await fetchImageUrl(
              message?.replayback?.file[0]?.path,
              user?.TOKEN
            );
            // if (fileData) {
            //   setReplyImageUrl(fileData);
            // }
            if (fileData) {
              newFileUrls[message?.replayback?.file[0]?.path] = fileData;
            }
          }
        })
      );
      setReplyImageUrl(prevUrls => ({ ...prevUrls, ...newFileUrls }));
    };

    fetchUrls();
  }, [messages]);
  // image url for status
  useEffect(() => {
    const fetchUrls = async () => {
      const newFileUrls = {};

      await Promise.all(
        messages.map(async message => {
          if (message?.status && message?.status?.message?.files != undefined) {
            const fileData = await fetchImageUrl(
              message?.status?.message?.files[0]?.path,
              user?.TOKEN
            );

            if (fileData) {
              newFileUrls[message?.status?.message?.files[0]?.path] = fileData;
            }
          }
        })
      );

      setImageUrl(prevUrls => ({ ...prevUrls, ...newFileUrls }));
    };

    fetchUrls();
  }, [messages]);

  // image url for normal images
  useEffect(() => {
    const fetchUrls = async () => {
      const newFileUrls = {};

      await Promise.all(
        messages.map(async message => {
          if (message?.messages?.files !== undefined) {
            const fileData = await fetchImageUrl(
              message?.messages?.files[0]?.path,
              user?.TOKEN
            );

            if (fileData) {
              newFileUrls[message?.messages?.files[0]?.path] = fileData;
            }
          }
        })
      );
      setImageUrl(prevUrls => ({ ...prevUrls, ...newFileUrls }));
    };

    fetchUrls();
  }, [messages]);

  // scroll
  let scrollRef = useRef();
  const [isAtBottom, setIsAtBottom] = useState(true);

  // Scroll to the bottom when `messages` change, if the user is at the bottom
  const scrollToBottom = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
    }
  };

  useEffect(() => {
    scrollToBottom(); // Call the function when messages change
  }, [messages]);

  // poll api  view
  let [viewPollData, setViewPollData] = useState([]);
  let [viewOptions, setViewOptions] = useState([]);

  const [selectedLabels, setSelectedLabels] = useState([]);

  //poll modal
  const [openPoll, setOpenPoll] = React.useState(false);
  const [openPollView, setOpenPollView] = React.useState(false);
  let [pollData, setPollData] = useState([]);
  let [maindata, setMainData] = useState([]);
  const handleOpenpoll = () => setOpenPoll(true);
  const handleClosepoll = () => {
    setOpenPoll(false);
  };
  const handleOpenpollView = () => setOpenPollView(true);
  const handleClosepollView = () => {
    setOpenPollView(false);
  };

  let handlePollView = e => {
    async function fetchData() {
      await AxiosInstance.get(`/employee/users_pollviewvotes?poll_id=${e}`, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      })
        .then(x => {
          let data = x?.data;
          let final = data?.polldata?.options;

          const updatedArray1 = final.filter(
            obj1 =>
              !data?.voters.some(obj2 => obj1.options_id === obj2.options_id)
          );
          const voterOptions = data?.voters?.map(voter => voter._id);

          // Filter updatedArray1 to remove objects with 'option' values that are also in voterOptions
          const filteredArray = updatedArray1.filter(
            obj => !voterOptions.includes(obj.options_id)
          );
          // Concatenate array2 to updatedArray1
          const finalArray = filteredArray.concat(data?.voters);
          // //(finalArray);
          // Update the state with the modified array
          setViewOptions(finalArray);
          setViewPollData(data);
        })
        .catch(y => {
          if (y?.response?.status === 403) {
            handleError(navigate, socket);
          }
        });
    }
    fetchData();
  };
  let handlePollAnswer = e => {
    async function fetchData() {
      setLoading(true);

      await AxiosInstance.get(`/employee/stu_getpoll?poll_id=${e}`, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      })
        .then(x => {
          let data = x?.data;
          let eee = x?.data?.voters[0]?.votefor?.map(x => x);

          let lll = data?.options?.map(outerOption => {
            return {
              ...outerOption,
              vote_type: data?.vote_type,
              isMatch:
                eee != undefined &&
                eee?.some(
                  voterOption =>
                    voterOption?.options_id === outerOption?.options_id
                ),
            };
          });

          let fff = lll.filter(x => {
            return x.isMatch === true;
          });
          setSelectedLabels(fff);
          setPollData(lll);
          setMainData(data);
          setLoading(false);
        })
        .catch(y => {
          setLoading(false);
          if (y?.response?.status === 403) {
            handleError(navigate, socket);
          }
        });
    }
    fetchData();
  };

  let HandleAnswerPoll = () => {

    if (selectedLabels.length === 0) {
      toast.error("Select any options", { position: "top-right" });
    } else {
      let payload = {
        pollid: maindata._id,
        userdata: {
          userid: user?._id,
          username: user?.username,
          number: user?.number,
          role: user?.role1,
          votefor: selectedLabels,
        },
      };
      async function students() {
        await AxiosInstance.post(`/employee/vote_poll`, payload, {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        })
          .then(x => {
            // console?.log(x);
          })
          .catch(x => {
            // console?.log(x);
            toast.error("There was an error", { position: "top-right" });
            if (x?.response?.status === 403) {
              handleError(navigate, socket);
            }
          })
          .finally(() => {
            setOpenPoll(false);
          });
      }
      students();
    }
  };

  let handleLabelClickRadio = option => {
    setSelectedLabels([option]);
  };

  // const handleLabelClick = user => {
  //   setSelectedLabels(prevLabels => {
  //     const labelIndex = prevLabels.findIndex(
  //       label => label.options_id === user.options_id
  //     );

  //     if (labelIndex !== -1) {
  //       return prevLabels.filter((_, index) => index !== labelIndex);
  //     } else {
  //       return [...prevLabels, user];
  //     }
  //   });
  // };
  const handleLabelClick = user => {
    setSelectedLabels(prevLabels =>
      prevLabels.some(label => label.options_id === user.options_id)
        ? prevLabels.filter(label => label.options_id !== user.options_id)
        : [...prevLabels, user]
    );
  };


  const SearchTextFilter = async value => {
    try {
      const { data } = await AxiosInstance.get(
        `/chat/msg/new/filter_sm_sf_sl?studentId=${particularTrainer?.studentId}&userId=${user._id}&filter=text_search&search=${searchText}&scroll=0`,
        {
          headers: {
            Authorization: `Bearer ${user.TOKEN}`,
          },
        }
      );

      let msg = data?.msgs?.reverse();
      setMessages(msg);
    } catch (error) {
      if (error?.response?.status === 403) {
        handleError(navigate, socket);
      }
    }
  };

  function highlightText(messageText, searchText) {
    if (!searchText) return messageText;

    const regex = new RegExp(`(${searchText})`, "gi");
    const parts = messageText.split(regex);

    return parts.map((part, index) =>
      regex.test(part) ? (
        <span key={index} style={{ backgroundColor: "blue", color: "white" }}>
          {part}
        </span>
      ) : (
        part
      )
    );
  }
  function highlightAndLinkify(messageText, searchText) {
    if (!messageText) return null;

    // Regex to identify URLs
    const urlRegex =
      /\b(https?|ftp|file):\/\/[A-Z0-9+&@#\/%?=~_|!:,.;-]*[A-Z0-9+&@#\/%=~_|-]/gi;

    // Split text into parts based on URLs
    const parts = messageText.split(urlRegex);

    // Match all valid URLs
    const urls = messageText.match(urlRegex) || [];

    // Function to render links
    function openInNewTab(url) {
      return (
        <a href={url} target="_blank" rel="noopener noreferrer" key={url}>
          {url}
        </a>
      );
    }

    let urlIndex = 0;

    // Map over parts and handle text and URLs separately
    return parts.map((part, index) => {
      if (index % 2 === 1) {
        // Odd index means this should be a URL from `urls`
        const url = urls[urlIndex++];
        return openInNewTab(url);
      } else {
        // Even index means non-URL text
        if (searchText) {
          // Highlight search text
          const regex = new RegExp(`(${searchText})`, "gi");
          const highlightedParts = part.split(regex);

          return highlightedParts.map((subPart, subIndex) =>
            regex.test(subPart) ? (
              <span
                key={`${index}-${subIndex}`}
                style={{ backgroundColor: "blue", color: "white" }}
              >
                {subPart}
              </span>
            ) : (
              subPart
            )
          );
        }
        return part; // Plain text if no searchText
      }
    });
  }
  let currentDate = new Date();
  let year = currentDate.getFullYear();
  let month = String(currentDate.getMonth() + 1).padStart(2, "0");
  let day = String(currentDate.getDate()).padStart(2, "0");
  let hours = String(currentDate.getHours()).padStart(2, "0");
  let minutes = String(currentDate.getMinutes()).padStart(2, "0");
  let currentDateTime = `${year}-${month}-${day} ${hours}:${minutes}`;
  const handleSectionChange = async section => {
    setActiveSection(section);
    if (section != "Search") {
      let apiEndpoint = "";

      // Determine the API endpoint based on the section
      switch (section) {
        case "File":
          apiEndpoint = "files";
          break;
        case "Poll":
          apiEndpoint = "poll";
          break;
        case "Links":
          apiEndpoint = "links";
          break;
      }

      // Call the API for the selected section
      setLoading(true);
      try {
        const { data } = await AxiosInstance.get(
          `/chat/msg/new/filter_sm_sf_sl?studentId=${particularTrainer?.studentId}&userId=${user._id}&filter=${apiEndpoint}&scroll=0`,
          {
            headers: {
              Authorization: `Bearer ${user.TOKEN}`,
            },
          }
        );

        let msg = data?.msgs?.reverse();
        setMessages(msg);
      } catch (error) {
        console.error(`Error fetching ${section.toLowerCase()} data:`, error);
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <>
      <Dialog
        aria-labelledby="poll-modal-title"
        aria-describedby="poll-modal-description"
        open={openPollView}
        onClose={handleClosepollView}
        fullWidth
        maxWidth="sm"
        sx={{
          "& .MuiDialog-paper": {
            padding: 1.5,
            borderRadius: 2,
          },
        }}
      >
        <DialogTitle
          sx={{
            color: "#f16f1d",
            textAlign: "center",
            fontWeight: "bold",
            fontSize: "1.2rem",
          }}
        >
          Poll Details
        </DialogTitle>
        <DialogContent
          sx={{
            overflowY: "auto",
            maxHeight: "75vh",
            padding: 1.5,
          }}
        >
          <Typography
            variant="body1"
            sx={{
              color: "green",
              textAlign: "center",
              mb: 1,
              fontSize: "0.95rem",
            }}
          >
            <Typography
              component="span"
              variant="body2"
              sx={{ color: "black", fontSize: "0.9rem" }}
            >
              Question:
            </Typography>{" "}
            {viewPollData?.polldata?.poll_question}
          </Typography>
          <Divider sx={{ mb: 1 }} />
          <List dense>
            {viewOptions?.map((ele, ind) => (
              <React.Fragment key={ind}>
                <ListItem
                  alignItems="flex-start"
                  sx={{
                    padding: "4px 8px",
                  }}
                >
                  <ListItemText
                    sx={{ display: "flex", justifyContent: "space-between" }}
                    primary={
                      <Typography
                        variant="body2"
                        sx={{
                          fontWeight: "bold",
                          fontSize: "0.9rem",
                        }}
                      >
                        {ele?.option}
                      </Typography>
                    }
                    secondary={
                      <React.Fragment>
                        <Typography
                          variant="body2"
                          sx={{
                            color: "gray",
                            fontSize: "0.8rem",
                          }}
                        >
                          Votes: {ele?.voters?.length || 0}
                        </Typography>
                        {ele?.voters?.length > 0 ? (
                          ele.voters.map((voter, voterIndex) => (
                            <Typography
                              key={voterIndex}
                              variant="body2"
                              sx={{
                                textTransform: "capitalize",
                                color: "black",
                                fontSize: "0.8rem",
                                ml: 2,
                              }}
                            >
                              {voter.username}
                            </Typography>
                          ))
                        ) : (
                          <Typography
                            variant="body2"
                            sx={{
                              fontStyle: "italic",
                              color: "gray",
                              fontSize: "0.8rem",
                              ml: 2,
                            }}
                          >
                            No voters yet
                          </Typography>
                        )}
                      </React.Fragment>
                    }
                  />
                </ListItem>
                {ind < viewOptions.length - 1 && <Divider component="li" />}
              </React.Fragment>
            ))}
          </List>
        </DialogContent>
      </Dialog>

      {/* Answer poll vote */}
      <Dialog
        open={openPoll}
        onClose={handleClosepoll}
        fullWidth
        maxWidth="sm"
        sx={{
          "& .MuiDialog-paper": {
            padding: 2,
            borderRadius: 2,
          },
        }}
      >
        <DialogTitle
          sx={{
            textAlign: "center",
            color: "#f16f1d",
            fontWeight: "bold",
          }}
        >
          Poll
        </DialogTitle>
        <DialogContent
          sx={{
            overflowY: "auto",
            maxHeight: "70vh",
          }}
        >
          <Typography
            variant="body1"
            sx={{
              color: "green",
              textAlign: "center",
              mb: 2,
            }}
          >
            <Typography
              component="span"
              variant="body2"
              sx={{ color: "black", fontSize: "0.9rem" }}
            >
              Question:
            </Typography>{" "}
            {maindata?.poll_question}
          </Typography>
          {maindata?.vote_type && (
            <Typography
              variant="body2"
              sx={{
                textAlign: "center",
                color: "gray",
                mb: 2,
              }}
            >
              Select one or more
            </Typography>
          )}
          {loading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CircularProgress />
            </div>
          ) : (
            <form>
              <FormControl
                component="fieldset"
                sx={{ display: "flex", flexDirection: "column", gap: 1.5 }}
              >
                {pollData?.map((ele, ind) => (
                  <React.Fragment key={ele?.options_id}>
                    {maindata?.vote_type === false ? (
                      <div className={styles.radioContainer}>
                        <input
                          type="radio"
                          name="poll_option"
                          value={ele?.options_id}
                          id={ele?.options_id}
                          defaultChecked={ele?.isMatch === true}
                          className={styles.radioInput}
                        />
                        <label
                          htmlFor={ele?.options_id}
                          className={styles.radioLabel}
                          onClick={() => {
                            handleLabelClickRadio(ele);
                          }}
                        >
                          {ele?.option}
                        </label>
                      </div>
                    ) : (
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={selectedLabels.some(
                              label => label.options_id === ele?.options_id
                            )} // Use checked instead of defaultChecked
                            onChange={() => handleLabelClick(ele)} // Use onChange instead of onClick for handling checkbox state changes
                            value={ele?.options_id}
                          />
                        }
                        label={ele?.option}
                      />
                    )}
                  </React.Fragment>
                ))}
              </FormControl>
            </form>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            sx={{
              backgroundColor: "#f16f1d",
              "&:hover": {
                backgroundColor: "#d95e1a",
              },
              borderRadius: "50%",
              minWidth: "48px",
              height: "48px",
              padding: 0,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            onClick={HandleAnswerPoll}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="23px"
              height="23px"
              viewBox="0 0 24 24"
            >
              <path
                fill="#fff"
                d="m12.815 12.197l-7.532 1.255a.5.5 0 0 0-.386.318L2.3 20.728c-.248.64.421 1.25 1.035.942l18-9a.75.75 0 0 0 0-1.341l-18-9c-.614-.307-1.283.303-1.035.942l2.598 6.958a.5.5 0 0 0 .386.318l7.532 1.255a.2.2 0 0 1 0 .395"
              ></path>
            </svg>
            {/* <IoSendSharp size={20} /> */}
          </Button>
        </DialogActions>
      </Dialog>
      <Box
        display="flex"
        justifyContent="space-around"
        alignItems="center"
        sx={{
          borderBottom: "1px solid #ddd",
          pb: 1,
          mb: 2,
        }}
      >
        {[
          { label: "Search", icon: "🔍" },
          { label: "File", icon: "📂" },
          { label: "Poll", icon: "📊" },
          { label: "Links", icon: "🔗" },
          //   { label: "Search", icon: "🔍" },
          //   { label: "File", icon: "📂" },
          //   { label: "Poll", icon: "📊" },

          //   { label: "Links", icon: "🔗" },
        ].map(({ label, icon }) => (
          <Button
            key={label}
            onClick={() => {
              setMessages([]);
              setSearchText("");
              setActiveSection(label);
              handleSectionChange(label);
            }}
            sx={{
              flex: 1,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              color: activeSection === label ? "#f16f1d" : "text.primary",
              borderBottom:
                activeSection === label ? "2px solid #f16f1d" : "none",
              py: 1,
              mx: 0.5,
            }}
          >
            <span style={{ fontSize: "20px" }}>{icon}</span>
            <span>{label}</span>
          </Button>
        ))}
      </Box>
      {/* Search Input */}
      {activeSection === "Search" && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            mb: 4,
          }}
        >
          <TextField
            placeholder="Search Messages"
            value={searchText}
            onChange={e => setSearchText(e.target.value)}
            variant="outlined"
            sx={{
              width: "40%",
              bgcolor: "#f16f1d1a",
              borderRadius: 1,
              "& .MuiOutlinedInput-root": {
                height: "36px",
                "& fieldset": {
                  borderColor: "#f16f1d",
                },
                "&:hover fieldset": {
                  borderColor: "#f16f1d",
                },
                "&.Mui-focused fieldset": {
                  borderColor: "#f16f1d", // Focus state
                },
              },
            }}
            InputProps={{
              sx: {
                padding: "0 10px", // Reduce internal padding for smaller height
                fontSize: "14px", // Smaller font size
              },
            }}
          />

          <Button
            variant="contained"
            sx={{
              bgcolor: "#f16f1d",
              color: "white",
              height: "33px", // Same height as input
              px: 3,
              ml: 1,
              "&:hover": {
                bgcolor: "#d55e1a", // Darker shade on hover
              },
            }}
            onClick={SearchTextFilter}
          >
            Search
          </Button>
          <Button
            sx={{ ml: 1 }}
            variant="contained"
            color="primary"
            onClick={clearSearch}
          >
            Clear
          </Button>
        </Box>
      )}

      {/* Chat Room */}
      <Box
        ref={scrollRef}
        sx={{
          flex: 1,
          overflowY: "auto",
          display: "flex",
          flexDirection: "column",
          gap: 1,
          p: 1,
          bgcolor: "#f5f5f5",
          borderRadius: 1,
          height: "78vh",
          overflow: "auto",
        }}
      >
        {messages.map((message, index) => (
          <>
            {message?.replayback &&
            Object.keys(message?.replayback).length > 0 ? (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems:
                    message?.sender === user._id ? "flex-end" : "flex-start", // Adjust alignment based on sender

                  marginY: 1,
                }}
              >
                {/* Message Bubble */}
                <Box
                  sx={{
                    backgroundColor:
                      message?.sender === user._id ? "#f16f1d" : "#fff",
                    color: message?.sender === user._id ? "white" : "black",
                    borderRadius: 2,
                    padding: 1,
                    boxShadow: 1,
                    position: "relative",
                  }}
                >
                  {/* Replied Message Section */}
                  {message?.replayback &&
                    (message?.replayback?.message ||
                      message?.replayback?.file) && (
                      <Box
                        sx={{
                          backgroundColor:
                            message?.sender === user._id
                              ? "#f5a573"
                              : "#f0f0f0",
                          borderRadius: 1,
                          padding: 1,
                          marginBottom: 1,
                          borderLeft: "4px solid",
                          borderColor:
                            message?.sender === user._id ? "#f16f1d" : "#ccc",
                        }}
                      >
                        Reply {/* Replied Text */}
                        {message?.replayback?.message && (
                          <Typography
                            variant="body2"
                            sx={{ fontStyle: "italic" }}
                          >
                            {message?.replayback?.message?.length > 85
                              ? `${message?.replayback?.message.slice(
                                  0,
                                  85
                                )}...`
                              : message?.replayback?.message}
                          </Typography>
                        )}
                        {/* Replied Image/File */}
                        {message?.replayback?.file?.length > 0 &&
                          (() => {
                            switch (message?.replayback?.file[0]?.mimetype) {
                              case "image/jpg":
                              case "image/jpeg":
                              case "image/avif":
                              case "image/png":
                              case "image/webp":
                              case "image/tiff":
                              case "image/bmp":
                                return (
                                  <Box
                                    component="img"
                                    // src={replyimgUrl?.url}
                                    src={
                                      replyimgUrl[
                                        message?.replayback?.file[0]?.path
                                      ]?.url
                                    }
                                    alt="Replied Image"
                                    sx={{
                                      width: "100%",
                                      height: "100px",
                                      borderRadius: "5px",
                                      marginTop: 1,
                                      cursor: "pointer",
                                    }}
                                    onClick={e => {
                                      stopPropagation(e);
                                      handleImage(
                                        replyimgUrl[
                                          message?.replayback?.file[0]?.path
                                        ]?.url
                                      );
                                    }}
                                  />
                                );
                              default:
                                return (
                                  <Button
                                    variant="outlined"
                                    color="primary"
                                    size="small"
                                    onClick={e => stopPropagation(e)}
                                    sx={{
                                      textTransform: "none",
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                      marginTop: 1,
                                    }}
                                  >
                                    {message?.replayback?.file[0]?.originalname}
                                  </Button>
                                );
                            }
                          })()}
                      </Box>
                    )}

                  {/* Main Message */}
                  <Typography variant="body2">
                    {message?.messages?.text}
                  </Typography>
                  <Typography
                    variant="caption"
                    sx={{
                      display: "block",
                      marginTop: 0.5,
                      textAlign: "right",
                    }}
                  >
                    {message?.createdAt ? (
                      <Moment format="MMM DD - h:mm a">
                        {message?.createdAt}
                      </Moment>
                    ) : (
                      <Moment format="MMM DD YYYY h A">
                        {message?.createdAt}
                      </Moment>
                    )}
                  </Typography>
                </Box>
              </Box>
            ) : message?.status && message?.status != null ? (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems:
                    message?.sender === user._id ? "flex-end" : "flex-start", // Adjust alignment based on sender

                  marginY: 1,
                }}
              >
                {/* Message Bubble */}
                <Box
                  sx={{
                    backgroundColor:
                      message?.sender === user._id ? "#f16f1d" : "#fff",
                    color: message?.sender === user._id ? "white" : "black",
                    borderRadius: 2,
                    padding: 1,
                    boxShadow: 1,
                    position: "relative",
                  }}
                >
                  {/* Replied Message Section */}
                  {message?.status &&
                    (message?.status?.message?.text ||
                      message?.status?.message?.files) && (
                      <Box
                        sx={{
                          backgroundColor:
                            message?.sender === user._id
                              ? "#f5a573"
                              : "#f0f0f0",
                          borderRadius: 1,
                          padding: 1,
                          marginBottom: 1,
                          borderLeft: "4px solid",
                          borderColor:
                            message?.sender === user._id ? "#f16f1d" : "#ccc",
                        }}
                      >
                        Reply {/* Replied Text */}
                        {message?.status?.message && (
                          <Typography
                            variant="body2"
                            sx={{ fontStyle: "italic" }}
                          >
                            {message?.status?.message?.text?.length > 85
                              ? `${message?.status?.message?.text.slice(
                                  0,
                                  85
                                )}...`
                              : message?.status?.message?.text}
                          </Typography>
                        )}
                        {/* Replied Image/File */}
                        {message?.status?.message?.files?.length > 0 &&
                          (() => {
                            switch (
                              message?.status?.message?.files[0]?.mimetype
                            ) {
                              case "image/jpg":
                              case "image/jpeg":
                              case "image/avif":
                              case "image/png":
                              case "image/webp":
                              case "image/tiff":
                              case "image/bmp":
                                return (
                                  <Box
                                    component="img"
                                    // src={replyimgUrl?.url}
                                    src={
                                      imageUrl[
                                        message?.messages?.files[0]?.path
                                      ]?.url
                                    }
                                    alt="Replied Image"
                                    sx={{
                                      width: "100%",
                                      height: "100px",
                                      borderRadius: "5px",
                                      marginTop: 1,
                                      cursor: "pointer",
                                    }}
                                    onClick={e => {
                                      stopPropagation(e);
                                      handleImage(
                                        imageUrl[
                                          message?.messages?.files[0]?.path
                                        ]?.url
                                      );
                                    }}
                                  />
                                );
                              default:
                                return (
                                  <Button
                                    variant="outlined"
                                    color="primary"
                                    size="small"
                                    onClick={e => stopPropagation(e)}
                                    sx={{
                                      textTransform: "none",
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                      marginTop: 1,
                                    }}
                                  >
                                    {
                                      message?.status?.message?.files[0]
                                        ?.originalname
                                    }
                                  </Button>
                                );
                            }
                          })()}
                      </Box>
                    )}

                  {/* Main Message */}
                  <Typography variant="body2">
                    {message?.messages?.text}
                  </Typography>
                  <Typography
                    variant="caption"
                    sx={{
                      display: "block",
                      marginTop: 0.5,
                      textAlign: "right",
                    }}
                  >
                    {message?.createdAt ? (
                      <Moment format="MMM DD - h:mm a">
                        {message?.createdAt}
                      </Moment>
                    ) : (
                      <Moment format="MMM DD YYYY h A">
                        {message?.createdAt}
                      </Moment>
                    )}
                  </Typography>
                </Box>
              </Box>
            ) : message?.poll && message?.poll !== null ? (
              <Box
                sx={{
                  border: "1px solid #ccc",
                  borderRadius: "8px",
                  padding: "8px",
                  maxWidth: "300px",
                  backgroundColor: "#f9f9f9",
                  boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
                  fontFamily: "Arial, sans-serif",
                }}
              >
                {/* Poll Header */}
                <Typography
                  variant="subtitle2"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "12px",
                    textTransform: "uppercase",
                    marginBottom: "4px",
                    color: "#f16f1d",
                  }}
                >
                  Poll
                </Typography>

                {/* Poll Question */}
                <Typography
                  variant="body2"
                  sx={{
                    fontWeight: 500,
                    color: "#111",
                    marginBottom: "8px",
                    fontSize: "14px",
                    lineHeight: 1.2,
                  }}
                >
                  {message?.poll?.poll_question}
                </Typography>

                {/* Poll Options */}
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "6px",
                    marginBottom: "8px",
                  }}
                >
                  {/* {message?.poll?.options?.map((option, index) => ( */}
                  <Box
                    key={index}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      backgroundColor: "#fff",
                      borderRadius: "4px",
                      border: "1px solid #ddd",
                      padding: "4px 8px",
                    }}
                  >
                    {currentDateTime < message?.poll?.endTime && (
                      <Button
                        size="small"
                        variant="text"
                        sx={{
                          fontSize: "12px",
                          textTransform: "none",
                          color: "#007bff",
                          padding: "2px 6px",
                          minWidth: "unset",
                        }}
                        onClick={e => {
                          stopPropagation(e);
                          handlePollAnswer(message?.poll?._id);
                          handleOpenpoll();
                        }}
                      >
                        Vote
                      </Button>
                    )}
                    {message?.poll?.stu_view_vote && (
                      <Button
                        size="small"
                        sx={{
                          fontSize: "12px",
                          textTransform: "none",
                          color: "#007bff",
                          padding: "2px 6px",
                          minWidth: "unset",
                        }}
                        onClick={e => {
                          stopPropagation(e);
                          handlePollView(message?.poll?._id);
                          handleOpenpollView();
                        }}
                      >
                        View
                      </Button>
                    )}
                  </Box>
                </Box>

                {/* Poll Footer */}
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  {currentDateTime < message?.poll?.endTime ? (
                    <Typography
                      variant="caption"
                      sx={{
                        fontSize: "10px",
                        color: "#888",
                      }}
                    >
                      Ends at{" "}
                      {new Date(message?.poll?.endTime).toLocaleString()}
                    </Typography>
                  ) : (
                    <Typography
                      variant="caption"
                      sx={{
                        fontSize: "10px",
                        color: "red",
                      }}
                    >
                      Poll Expired
                    </Typography>
                  )}
                  <Typography
                    variant="caption"
                    sx={{
                      display: "block",
                      marginTop: 0.5,
                      textAlign: "right",
                    }}
                  >
                    {message?.createdAt ? (
                      <Moment format="MMM DD - h:mm a">
                        {message?.createdAt}
                      </Moment>
                    ) : (
                      <Moment format="MMM DD YYYY h A">
                        {message?.createdAt}
                      </Moment>
                    )}
                  </Typography>
                </Box>
              </Box>
            ) : (
              <Box
                key={index}
                sx={{
                  display: "flex",
                  justifyContent:
                    message.sender === user._id ? "flex-end" : "flex-start",
                }}
              >
                <Box
                  sx={{
                    maxWidth: "60%",
                    p: 1,
                    borderRadius: 2,
                    bgcolor: message.sender === user._id ? "#f16f1d" : "#fff",
                    color: message.sender === user._id ? "white" : "black",
                    boxShadow: 1,
                  }}
                >
                  {message?.messages?.text ? (
                    message?.messages?.text && message?.html ? (
                      <Linkify
                        componentDecorator={(
                          decoratedHref,
                          decoratedText,
                          key
                        ) => (
                          <a
                            href={decoratedHref}
                            target="_blank"
                            rel="noopener noreferrer"
                            key={key}
                          >
                            {decoratedText}
                          </a>
                        )}
                      >
                        <pre
                          dangerouslySetInnerHTML={{
                            __html: linkifyHtmlContent(
                              message?.messages?.text || ""
                            ),
                          }}
                          // className={Styles.textEditStyle}
                        />
                      </Linkify>
                    ) : (
                      <>
                        <Typography variant="body2">
                          {highlightAndLinkify(
                            message.messages.text,
                            searchText
                          )}
                        </Typography>
                        {/* <Typography
                          variant="caption"
                          sx={{
                            display: "block",
                            marginTop: 0.5,
                            textAlign: "right",
                          }}
                        >
                          {message?.createdAt ? (
                            <Moment format="MMM DD - h:mm a">
                              {message?.createdAt}
                            </Moment>
                          ) : (
                            <Moment format="MMM DD YYYY h A">
                              {message?.createdAt}
                            </Moment>
                          )}
                        </Typography> */}
                      </>
                    )
                  ) : message?.messages?.files?.length > 0 ? (
                    <>
                      {(() => {
                        switch (message?.messages?.files[0].mimetype) {
                          case "image/jpg":
                          case "image/jpeg":
                          case "image/avif":
                          case "image/png":
                          case "image/webp":
                          case "image/tiff":
                          case "image/bmp":
                            return (
                              <>
                                <Box
                                  component="img"
                                  src={
                                    imageUrl[message?.messages?.files[0]?.path]
                                      ?.url
                                  }
                                  alt="Image"
                                  sx={{
                                    width: "100%",
                                    height: "100px",
                                    borderRadius: "5px",
                                    marginTop: 1,
                                    cursor: "pointer",
                                  }}
                                  onClick={e => {
                                    stopPropagation(e);
                                    handleImage(
                                      imageUrl[
                                        message?.messages?.files[0]?.path
                                      ]?.url
                                    );
                                  }}
                                />
                                <Link
                                  href="#"
                                  underline="hover"
                                  color="primary"
                                  onClick={e => {
                                    e.preventDefault(); // Prevent navigation
                                    if (!isInProgress) {
                                      download(
                                        imageUrl[
                                          message?.messages?.files[0]?.path
                                        ]?.url,
                                        message?.messages?.files[0]
                                          ?.originalname,
                                        message?.messages?.files[0].filename
                                      );
                                    }
                                  }}
                                >
                                  Click to Download
                                </Link>
                              </>
                            );
                          case "application/docx":
                          case "application/doc":
                          case "application/msword":
                          case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
                          case "application/vnd.openxmlformats-officedocument.wordprocessingml.template":
                          case "application/vnd.ms-word.document.macroEnabled.12":
                          case "application/vnd.ms-excel":
                          case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
                          case "application/vnd.openxmlformats-officedocument.spreadsheetml.template":
                          case "application/vnd.ms-excel.sheet.macroEnabled.12":
                          case "application/vnd.ms-powerpoint":
                          case "application/xlsx":
                          case "application/pptx":
                          case "application/octet-stream":
                          case "text/plain":
                          case "text/html":
                          case "application/apk":
                          case "application/zip":
                          case "application/x-zip":
                          case "application/octet-stream":
                          case "application/x-zip-compressed":
                          case "application/pdf":
                          case "application/x-pdf":
                          case "application/x-gzpdf":
                          case "applications/vnd.pdf":
                          case "application/acrobat":
                          case "application/x-google-chrome-pdf":
                          case "text/pdf":
                          case "text/x-pdf":
                          case "text/csv":
                          case "application/json":
                          case "video/mp4":
                          case "video/x-matroska":
                          case "video/x-ms-wmv":
                            return (
                              <Box>
                                <Typography variant="body2" sx={{ mb: 1 }}>
                                  {message?.messages?.files[0]?.originalname}
                                </Typography>
                                <Link
                                  href="#"
                                  underline="hover"
                                  color="primary"
                                  onClick={e => {
                                    e.preventDefault(); // Prevent navigation
                                    if (!isInProgress) {
                                      download(
                                        imageUrl[
                                          message?.messages?.files[0]?.path
                                        ]?.url,
                                        message?.messages?.files[0]
                                          ?.originalname,
                                        message?.messages?.files[0].filename
                                      );
                                    }
                                  }}
                                >
                                  Click to Download
                                </Link>
                                {/* <Button
                                  // startIcon={<DownloadIcon />}
                                  variant="outlined"
                                  disabled={isInProgress}
                                  onClick={() =>
                                    download(
                                      imageUrl[
                                        message?.messages?.files[0]?.path
                                      ]?.url,
                                      message?.messages?.files[0]?.originalname,
                                      message?.messages?.files[0].filename
                                    )
                                  }
                                >
                                  Download
                                </Button> */}
                              </Box>
                            );
                          case "audio/mpeg":
                          case "audio/mp3":
                          case "audio/webm":
                          case "application/wav":
                          case "video/webm":
                          case "audio/wav":
                            return (
                              <Box sx={{ textAlign: "center", mb: 2 }}>
                                {/* Display File Name */}
                                <Typography
                                  variant="body2"
                                  sx={{ mb: 1, fontWeight: "bold" }}
                                >
                                  {message?.messages?.files[0]?.originalname}
                                </Typography>

                                {/* Audio Player */}
                                <audio
                                  src={
                                    imageUrl[message?.messages?.files[0]?.path]
                                      ?.url
                                  }
                                  controls
                                  style={{
                                    width: "250px",
                                    height: "40px",
                                    borderRadius: "8px",
                                    outline: "none",
                                  }}
                                >
                                  Your browser does not support the audio
                                  element.
                                </audio>
                              </Box>
                            );
                          default:
                            return (
                              <Box sx={{ textAlign: "center" }}>
                                <Typography variant="body2" sx={{ mb: 1 }}>
                                  {message?.messages?.files[0]?.originalname}
                                </Typography>
                                <Link
                                  href="#"
                                  underline="hover"
                                  color="primary"
                                  onClick={e => {
                                    e.preventDefault(); // Prevent navigation
                                    if (!isInProgress) {
                                      download(
                                        imageUrl[
                                          message?.messages?.files[0]?.path
                                        ]?.url,
                                        message?.messages?.files[0]
                                          ?.originalname,
                                        message?.messages?.files[0].filename
                                      );
                                    }
                                  }}
                                >
                                  Click to Download
                                </Link>
                              </Box>
                            );
                        }
                      })()}
                    </>
                  ) : (
                    <h1 style={{ display: "none" }}></h1>
                  )}
                  <Typography
                    variant="caption"
                    sx={{
                      display: "block",
                      marginTop: 0.5,
                      textAlign: "right",
                    }}
                  >
                    {message?.createdAt ? (
                      <Moment format="MMM DD - h:mm a">
                        {message?.createdAt}
                      </Moment>
                    ) : (
                      <Moment format="MMM DD YYYY h A">
                        {message?.createdAt}
                      </Moment>
                    )}
                  </Typography>
                </Box>
              </Box>
            )}
          </>
        ))}
      </Box>
    </>
  );
};

export default UserFilter;
