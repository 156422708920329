import React, {
  useContext,
  useEffect,
  useRef,
  useState,
  Fragment,
} from "react";
import AxiosInstance from "../../../api/AxiosInstance";
import { useNavigate } from "react-router-dom";
import { SocketContext } from "../../../ioContext/socketIo";
import { toast } from "react-toastify";
import handleError from "../../Users/ErrorHandler";
import Styles from "./_chat.module.css";
import bg6 from "../../../assets/bg6.webp";
import { ImSpinner11 } from "react-icons/im";
import { AiFillDelete } from "react-icons/ai";
import { ImAttachment } from "react-icons/im";
import {
  BsFillMicFill,
  BsFillMicMuteFill,
  BsFillPlayCircleFill,
} from "react-icons/bs";
import StuMessage from "./StuMessage";
import StudentName from "./StudentName";
import StuConversation from "./StuConversation";
import { RotatingLines } from "react-loader-spinner";
import { Tooltip, Whisper } from "rsuite";
import { Modal, ButtonToolbar } from "rsuite";
import FileUpload from "./StudFileUpload";
import wavEncoder from "wav-encoder";
import {
  Box,
  Button,
  Checkbox,
  Chip,
  Divider,
  FormControlLabel,
  Modal as MUIModal,
  styled,
  TextField,
  Typography,
} from "@mui/material";
import RemoveHtmlTag from "../../functions/RemoveHtmlTag";

const styleModal2 = {
  position: "absolute",
  top: "45%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "60%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 2,
  borderRadius: "10px",
  outline: "1px solid #f57c00",
  outlineOffset: "3px",
  minHeight: "min-content",
  maxHeight: "90vh",
  overflowY: "auto",
};

const ListItem = styled("li")(({ theme }) => ({
  margin: theme.spacing(0.5),
  listStyleType: "none",
  fontSize: "x-small",
  color: "#f57c00",
}));

const ListContainer = styled("ul")(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  flexWrap: "wrap",
  padding: 0,
  width: "100%",
  maxHeight: "100px",
  overflow: "auto",
}));

const NewChatComp = () => {
  let navigate = useNavigate();

  const user1 = sessionStorage.getItem("user");
  const user = JSON.parse(user1);

  const userToken = sessionStorage.getItem("token");

  const socket = useContext(SocketContext);
  let scrollRef = useRef();
  let [loading, setLoading] = useState(false);

  let [selctBatchStud, setSelctBatchStud] = useState([]);

  let [particularStudent, setParticularStudent] = useState({});
  const [pastedImage, setPastedImage] = useState(null);
  // messages
  let [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const [arrivalMessage, setArrivalMessage] = useState([]);
  const [arrivalMessageFile, setArrivalMessageFile] = useState([]);
  const [batchname, setBatchname] = useState([]);
  const [msgseen, setMsgSeen] = useState("");

  const [open, setOpen] = React.useState(false);
  // const [backdrop, setBackdrop] = React.useState("static");
  let [permenentBlock, setPermanentBlock] = useState(false);

  const [reply, setReply] = useState(false);
  const [selectedMessages, setSelectedMessages] = useState([]);

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    // setImagePreview(null);
    setOpen(false);
  };

  //online
  let [online, setOnline] = useState();
  // api
  let [batchBlock, setBatchBlock] = useState(false);
  let [data, setSData] = useState("");

  //filter
  const [isSpinning, setIsSpinning] = useState(false);
  const [activeSection, setActiveSection] = useState("");
  const [searchText, setSearchText] = useState("");

  const [openFilter, setOpenFilter] = useState(false);
  const handleOpenFilter = () => setOpenFilter(true);
  const handleCloseFilter = () => {
    setOpenFilter(false);
  };
  const handleSpinnerClick = () => {
    setIsSpinning(true);
    SearchTextFilter();

    // Reset the animation state after it completes
    setTimeout(() => setIsSpinning(false), 1000); // Matches animation duration
  };

  const [totalFilterdLength, settotalFilterdLength] = useState(0);
  const [filterScroll, setFilterScroll] = useState(0);
  const [clickSearch, setClickSearch] = useState(false);

  const SearchTextFilter = async value => {
    if (searchText != "") {
      try {
        const { data } = await AxiosInstance.get(
          `/chat/msg/new/filter_sm_sf_sl?studentId=${user._id}&userId=${particularStudent?.trainersId}&filter=text_search&search=${searchText}&scroll=${filterScroll}`,
          {
            headers: {
              Authorization: `Bearer ${user.TOKEN}`,
            },
          }
        );

        let msg = data?.msgs?.reverse();

        if (filterScroll > 0) {
          setMessages(prevMessages => [...msg, ...prevMessages]);
          settotalFilterdLength(data?.filtermsgscount);
        } else {
          settotalFilterdLength(data?.filtermsgscount);
          setFilterScroll(prevScroll => prevScroll + 50);
          setMessages(msg);
        }
      } catch (error) {
        if (error?.response?.status === 403) {
          handleError(navigate, socket);
        }
      }
    }
    try {
      const { data } = await AxiosInstance.get(
        `/chat/msg/new/filter_sm_sf_sl?studentId=${user._id}&userId=${particularStudent?.trainersId}&filter=text_search&search=${searchText}&scroll=${filterScroll}`,
        {
          headers: {
            Authorization: `Bearer ${user.TOKEN}`,
          },
        }
      );

      let msg = data?.msgs?.reverse();

      if (filterScroll > 0) {
        setMessages(prevMessages => [...msg, ...prevMessages]);
        settotalFilterdLength(data?.filtermsgscount);
      } else {
        settotalFilterdLength(data?.filtermsgscount);
        setFilterScroll(prevScroll => prevScroll + 50);
        setMessages(msg);
      }
    } catch (error) {
      if (error?.response?.status === 403) {
        handleError(navigate, socket);
      }
    }
  };

  const handleSectionChange = async section => {
    setActiveSection(section);

    if (section != "Text search") {
      let apiEndpoint = "";

      // Determine the API endpoint based on the section
      switch (section) {
        case "File":
          apiEndpoint = "files";
          break;
        case "Poll":
          apiEndpoint = "poll";
          break;
        case "Links":
          apiEndpoint = "links";
          break;
      }

      // Call the API for the selected section
      setLoading(true);
      try {
        const { data } = await AxiosInstance.get(
          `/chat/msg/new/filter_sm_sf_sl?studentId=${user._id}&userId=${particularStudent?.trainersId}&filter=${apiEndpoint}&scroll=0`,
          {
            headers: {
              Authorization: `Bearer ${user.TOKEN}`,
            },
          }
        );

        let msg = data?.msgs?.reverse();
        if (filterScroll > 0) {
          setMessages(prevMessages => [...msg, ...prevMessages]);
          settotalFilterdLength(data?.filtermsgscount);
        } else {
          settotalFilterdLength(data?.filtermsgscount);
          setFilterScroll(prevScroll => prevScroll + 50);
          setMessages(msg);
        }
      } catch (error) {
        console.error(`Error fetching ${section.toLowerCase()} data:`, error);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleCancelFilter = () => {
    setActiveSection("");
    getconversations();
    setFilterScroll();
  };

  const handleClearSearchFilter = () => {
    setSearchText("");
    getconversations();
    setClickSearch(false);
  };
  // voice recoder//

  const [isRecording, setIsRecording] = useState(false);
  const [mediaRecorder, setMediaRecorder] = useState(null);
  const [recordedChunks, setRecordedChunks] = useState([]);
  // const [recordingStatus, setRecordingStatus] = useState("");
  const [isPlaybackActive, setIsPlaybackActive] = useState(false);

  const [recordedAudio, setRecordedAudio] = useState(null);
  // const [isRecording, setIsRecording] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [stop, setStop] = useState(false);
  const [isAudioVisible, setIsAudioVisible] = useState(false);
  let [msgScroll, setMsgScroll] = useState(0);

  let [messageCount, setMessageCount] = useState(0);
  let [lengthmsg, setLengthMsg] = useState(0);
  let [noContent, setNoContent] = useState(null);

  // mobile view media query
  const [isBackActive, setIsBackActive] = useState(false);
  const handleBackClick = () => {
    setIsBackActive(true);
  };
  const handleBackClickBack = () => {
    setIsBackActive(false);
  };
  let CancelReply = () => {
    setReply(false);
    setSelectedMessages([]);
  };

  const handleMessageClick = clickedMessage => {
    const existingIndex = selectedMessages.findIndex(
      msg =>
        (msg.messages.text === clickedMessage.messages.text &&
          msg.index === clickedMessage.index) ||
        (msg.messages.flies === clickedMessage?.messages?.flies &&
          msg.index === clickedMessage.index)
    );

    const isMessageAlreadySelected = existingIndex !== -1;
    const hasReachedMaxSelection = selectedMessages.length === 5;

    if (!isMessageAlreadySelected && selectedMessages.length < 5) {
      setSelectedMessages([...selectedMessages, clickedMessage]);
    } else if (isMessageAlreadySelected) {
      const updatedMessages = selectedMessages.filter(
        (_, index) => index !== existingIndex
      );
      setSelectedMessages(updatedMessages);
    } else if (selectedMessages.length === 5) {
      // Alert message when the selection limit is reached or duplicate is clicked
      alert(`You can select only 5 unique messages.`);
    }
  };

  useEffect(() => {
    let audioStream;
    let recorder;

    const startRecording = async () => {
      try {
        audioStream = await navigator.mediaDevices.getUserMedia({
          audio: true,
        });
        recorder = new MediaRecorder(audioStream);
        recorder.addEventListener("dataavailable", handleDataAvailable);
        recorder.start();
        setMediaRecorder(recorder);
        setIsRecording(true);
        // setRecordingStatus("Recording...");
      } catch (error) {
        alert("Error accessing microphone");
        // setStop(true)
        setIsRecording(false);

        console.error("Error accessing microphone:", error);
      }
    };

    const stopRecording = () => {
      if (mediaRecorder && mediaRecorder.state !== "inactive") {
        mediaRecorder.stop();
        mediaRecorder.removeEventListener("dataavailable", handleDataAvailable);
        setIsRecording(false);
        // setRecordingStatus("Recording stopped.");
      }
      if (audioStream) {
        audioStream.getTracks().forEach(track => track.stop());
      }
    };

    if (isRecording) {
      startRecording();
    } else {
      stopRecording();
      setRecordedChunks([]); // Clear recorded
    }

    return () => {
      stopRecording();
    };
  }, [isRecording]);

  const handleStartRecording = () => {
    setIsRecording(true);
  };

  const handleDataAvailable = event => {
    if (event.data.size > 0) {
      const audioData = event.data;

      setRecordedAudio(audioData);
      setRecordedChunks(prevChunks => [...prevChunks, event.data]);
    }
  };

  const handleStopRecording = () => {
    setStop(true);
    setIsRecording(false);

    if (recordedAudio) {
      // storeAudioLocally(recordedAudio);
    }
  };

  const handlePlayButtonClick = () => {
    setIsPlaying(true);
    setIsPlaybackActive(prevPlaybackState => !prevPlaybackState);
    // Clear recorded chunks when playback is triggered
  };

  useEffect(() => {
    if (recordedAudio) {
      storeAudioLocally(recordedAudio);
    }
  }, [recordedAudio]);

  const getAudioURL = () => {
    if (recordedChunks.length === 0) {
      console.log("No audio recorded");
      return null;
    }
    const audioBlob = new Blob(recordedChunks, { type: "audio/webm" });

    return URL.createObjectURL(audioBlob);
  };

  const audioPlayer = isPlaybackActive ? (
    <audio
      controls
      autoPlay
      key={isPlaybackActive ? "audio-playback" : "audio-empty"}
      // className={!stop ? `${Styles.AudioNot}` : `${Styles.Audio}`}
    >
      <source src={getAudioURL()} type="audio/webm" />
      Your browser does not support the audio element.
    </audio>
  ) : null;

  const storeAudioLocally = audioBlob => {
    try {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64String = reader.result.split(",")[1];
        localStorage.setItem("audioBlob", base64String);
        //("Audio stored locally");
      };
      reader.readAsDataURL(audioBlob);
    } catch (error) {
      console.error("Error storing audio locally:", error);
    }
  };

  let HandleAudio = async e => {
    e.preventDefault();
    setStop(false);
    setIsPlaying(false);
    const base64String = localStorage.getItem("audioBlob");
    const blobToArrayBuffer = blob => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => resolve(reader.result);
        reader.onerror = reject;
        reader.readAsArrayBuffer(blob);
      });
    };

    const decodeAudioData = arrayBuffer => {
      return new Promise((resolve, reject) => {
        const audioContext = new (window.AudioContext ||
          window.webkitAudioContext)();
        audioContext.decodeAudioData(arrayBuffer, resolve, reject);
      });
    };
    if (base64String) {
      const arrayBuffer = await blobToArrayBuffer(recordedAudio);
      const audioBuffer = await decodeAudioData(arrayBuffer);

      const wavArrayBuffer = await wavEncoder.encode({
        sampleRate: audioBuffer.sampleRate,
        channelData: [audioBuffer.getChannelData(0)], // Assuming mono audio
      });

      const wavBlob = new Blob([wavArrayBuffer], { type: "audio/wav" });
      const wavFile = new File(
        [wavBlob],
        `audio${Math.round(Math.random() * 100)}.wav`,
        {
          type: "audio/wav",
        }
      );

      if (wavFile !== "") {
        try {
          let payload = {
            wavFile,
            sender: user._id,
          };
          //(payload);
          const options = {
            headers: {
              Authorization: `Bearer ${userToken}`,
              "Content-Type": "multipart/form-data",
            },
          };

          const res = await AxiosInstance.post(
            "/chat/newmsgfiles",
            payload,
            options
          );

          let filesData = res.data.files;

          socket.emit(
            "sendMessage",
            {
              files: filesData,
              senderId: user._id,
              receiverId: particularStudent?.trainersId,
              role: user?.role1,
              code: "",
              type: "batch",
              device: "web",
              poll: null,
            },
            function callback(msg) {
              msg.tick = false;
              setMessages([...messages, msg]);
              setIsPlaybackActive(false);
              setIsAudioVisible(false); // Hide the audio tag
              setRecordedChunks([]); // Clear the recorded audio chunks
              //("end");
              setIsPlaying(false);
            }
          );
          setIsAudioVisible(false); // Hide the audio tag
          setRecordedChunks([]); // Clear the recorded audio chunks
          //("asdasdasda");
          setIsPlaying(false);
        } catch (error) {
          //(error);
        }
      } else {
      }
      // return file;
    }
    return null;
  };
  let handleDeleteButtonClick = () => {
    localStorage.removeItem("audioBlob");
    setStop(false);
    setIsPlaying(false);
    setRecordedAudio(null);
    setIsPlaybackActive(false);
    setIsAudioVisible(false); // Hide the audio tag
    setRecordedChunks([]); // Clear the recorded audio chunks
    setIsPlaying(false);
  };

  useEffect(() => {
    if (socket) {
      socket.on("batchclose_open", data => {
        //(data);
        setBatchBlock(true);
        setSData(data.result.res);
      });
    }
  }, [socket]);

  useEffect(() => {
    socket.on("batchclose_open", data => {
      //(data);
      setBatchBlock(true);
      setSData(data.result.res);
    });
  }, []);
  if (batchBlock === true) {
    toast.error(data, { position: "top-right" });
    function greeting() {
      // window.location.reload()
      navigate("/student-dashboard");
    }
    setTimeout(greeting, 3000);
    setBatchBlock(false);
    setSData("");
  }

  useEffect(() => {
    socket.on("blocked", data => {
      //(data);
      setPermanentBlock(true);
    });
  }, []);
  if (permenentBlock === true) {
    toast.error("Contact Administrator", { position: "top-right" });
    function greeting() {
      socket.disconnect();
      navigate("/");
      // window.location.reload();
    }
    setTimeout(greeting, 3000);
  }

  useEffect(() => {
    if (socket) {
      socket.on("getUsers", users => {});
      socket.on("online_offline", data => {});
      socket.on("getMessage", data => {
        if (data.brodcastchat) {
          setArrivalMessage(data);
        } else {
          setArrivalMessage(data);
        }
      });
    }
  }, [socket]);
  useEffect(() => {
    setMessages(prev => [...prev, arrivalMessage]);
  }, [particularStudent.trainersId && arrivalMessage]);

  useEffect(() => {
    if (arrivalMessageFile.length > 0) {
      setMessages(prevMessages => prevMessages.concat(arrivalMessageFile));
      setArrivalMessageFile([]);
    }
  }, [particularStudent.trainersId && arrivalMessageFile]);

  const getconversations = async () => {
    try {
      if (particularStudent?.trainersId !== undefined) {
        socket.emit(
          "isOnline",
          {
            senderId: user?._id,
            role: user?.role1,
            receiverId: particularStudent?.trainersId,
            device: "web",
          },
          function callback(msg) {
            setOnline(msg);
          }
        );

        socket.emit("chatwith", {
          senderId: user?._id,
          role: user?.role1,
          receiverId: particularStudent?.trainersId,
          device: "web",
        });

        if (particularStudent.notification === true) {
          socket.emit("msgRead", {
            senderId: user?._id,
            role: user?.role1,
            receiverId: particularStudent.trainersId,
            seen: true,
            code: batchname,
            count: particularStudent?.studentnoti,
            type: "batch",
            device: "web",
          });
        } else {
          socket.emit("msgRead", {
            senderId: user?._id,
            role: user?.role1,
            receiverId: particularStudent.trainersId,
            seen: false,
            code: batchname,
            count: particularStudent?.studentnoti,
            type: "batch",
            device: "web",
          });
        }

        const res = await AxiosInstance.post(
          `/chat/msg/new/allmsg?scroll=${msgScroll}`,
          {
            senderId: user._id,
            receiverId: particularStudent?.trainersId,
            userId: particularStudent?.trainersId,
            studentId: user._id,
            // code: batchCode,
          },
          {
            headers: {
              Authorization: `Bearer ${userToken}`,
            },
          }
        );

        res?.data?.msgs?.reverse();
        let fff = res?.data?.msgs;
        setMessageCount(res?.data?.msgscount);
        setLengthMsg(res?.data?.msgs?.length);
        // Get the last object in the array
        const lastIndex = fff.length - 1;
        const lastObject = fff[lastIndex];
        // Check if lastObject exists, and if it meets both conditions
        if (
          lastObject &&
          lastObject.sender === user._id &&
          new Date() - new Date(lastObject.createdAt) > 2 * 60 * 1000
        ) {
          fff[lastIndex] = {
            ...lastObject,
            escalate: "escalate",
          }; // Update with new key-value
        } else {
          console.log("");
        }

        if (res?.data?.msgs?.length > 0 && msgScroll > 0) {
          let mess = res?.data?.msgs;
          setMessages([...mess, ...messages]);
          setLoading(false);
        } else if (res?.data?.msgs?.length > 0 && msgScroll <= 0) {
          socket.on("msgSeen", data1 => {
            setMsgSeen(data1);

            res.data.msgs.map(x => {
              return { ...x, tick: true };
            });
          });

          for (
            let index = 1;
            index <= res?.data?.notification?.usersNotification;
            index++
          ) {
            const element =
              index > res?.data?.msgs?.length
                ? res?.data?.msgs[res?.data?.msgs?.length - 1]
                : res?.data?.msgs[res?.data?.msgs?.length - index];

            element.tick = false;
          }
          setMessages(res.data.msgs);
          setLoading(false);
        } else if (res?.data?.msgs?.length <= 0) {
          setMessages(res.data.msgs);

          setLoading(false);
        }
      }
      return () => {
        socket.off("msgSeen");
      };
    } catch (error) {
      if (error?.response?.status === 403) {
        handleError(navigate, socket);
      }
    }
  };

  useEffect(() => {
    getconversations();
  }, [user._id && particularStudent?.trainersId, msgseen, msgScroll]);
  let [likeShare, setLikeShare] = useState([]);
  useEffect(() => {
    const fetchShareData = async () => {
      const shareDataArray = []; // Temporary array to hold all the fetched data

      const requests = messages.map(async messa => {
        if (messa.share) {
          let e = messa.share;
          try {
            setLoading(true);
            const response = await AxiosInstance.get(
              `/users/share_like_selected?post_id=${e?.usershare_id}&studentid=${user._id}`,
              {
                headers: {
                  Authorization: `Bearer ${userToken}`,
                },
              }
            );
            const res = response.data.post_data;
            shareDataArray.push(res); // Collect the data in a temporary array
          } catch (error) {
            console.log(error);
          }
        }
      });

      // Wait for all requests to finish
      await Promise.all(requests);

      // Now update the state after all requests are complete
      setLikeShare(shareDataArray);

      setLoading(false);
    };

    if (messages.length > 0) {
      fetchShareData();
    }
  }, [messages]);

  const scrollToBottom = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
    }
  };

  useEffect(() => {
    scrollToBottom(); // Call the function when messages change
  }, [messages]);

  let handelSubmit = async e => {
    e?.preventDefault();

    if (newMessage.trim() !== "") {
      setLoading(true);
      if (reply) {
        const messageText = selectedMessages[0]?.messages?.text;
        const htmlContent = selectedMessages[0]?.html === true;
        const replyBack = {
          message: messageText
            ? htmlContent
              ? await RemoveHtmlTag(messageText)
              : messageText
            : "",
          file: selectedMessages[0]?.messages?.files || "",
        };
        socket?.emit(
          "sendMessage",
          {
            senderId: user._id,
            receiverId: particularStudent?.trainersId,
            text: newMessage,
            role: user?.role1,
            code: "",
            type: "batch",
            device: "web",
            poll: null,
            replayback: replyBack,
          },
          function callback(msg) {
            setReply(false);
            setSelectedMessages([]);

            if (msg.read === false) {
              msg.tick = false;
              setMessages([...messages, msg]);
              setLoading(false);
            } else {
              setMessages([...messages, msg]);
              setLoading(false);
            }
          }
        );
      } else {
        socket.emit(
          "sendMessage",
          {
            senderId: user._id,
            receiverId: particularStudent?.trainersId,
            text: newMessage,
            role: user?.role1,
            code: "",
            type: "batch",
            device: "web",
            poll: null,
          },
          function callback(msg) {
            if (msg.read === false) {
              msg.tick = false;

              setMessages([...messages, msg]);
              setLoading(false);
            } else {
              setMessages([...messages, msg]);
              setLoading(false);
            }
          }
        );
      }

      try {
        setNewMessage("");
      } catch (error) {
        if (error?.response?.status === 403) {
          handleError(navigate, socket);
        }
      }
    } else {
      //("first");
    }
  };

  let handelSubmitButton = e => {
    handelSubmit();
  };
  const handleKeyPress = event => {
    if (event.key === "Enter") {
      handelSubmit();
    }
  };

  const handlePaste = e => {
    e.preventDefault(); // Prevent the default paste behavior

    const clipboardData = e.clipboardData || window.clipboardData;
    const pastedText = clipboardData.getData("text"); // Get the text from the clipboard

    if (pastedText) {
      // Append the new pasted text to the existing message
      setNewMessage(prevMessage => prevMessage + pastedText);
    }

    // Handle image paste
    const items = clipboardData.items;
    for (let i = 0; i < items.length; i++) {
      const item = items[i];
      if (item.type.indexOf("image") !== -1) {
        setOpen(true);
        const file = item.getAsFile();
        const imageUrl = URL.createObjectURL(file);

        setPastedImage(imageUrl); // Store pasted image
        break;
      }
    }
  };

  let HandleLoadMessages = () => {
    setMsgScroll(msgScroll + lengthmsg);
  };

  const [recentChatUsersList, setRecentChatUsersList] = useState([]);
  const [defaultRecentChatUsersList, setDefaultRecentChatUsersList] = useState(
    []
  );

  const [selectedBatches, setSelectedBatches] = useState([]);

  const handleDelete = chipToDelete => () => {
    const updatedBatches = selectedBatches.filter(
      chip => chip !== chipToDelete
    );
    setSelectedBatches(updatedBatches);
    setTimeout(() => {
      if (updatedBatches.length === 0) {
        setIsFilterApplied(false);
        setSelectedBatches([]);
        toast.info("Filter Removed");
        let defaultVal = defaultRecentChatUsersList?.usersdata
          ? defaultRecentChatUsersList?.usersdata
          : [];
        setRecentChatUsersList(defaultVal);
      } else {
        deleteFilterFunction(updatedBatches);
      }
    }, 50);
  };

  const handleBatchChange = batchCode => {
    setSelectedBatches(prevSelectedBatches => {
      if (prevSelectedBatches.includes(batchCode)) {
        return prevSelectedBatches.filter(code => code !== batchCode);
      } else {
        return [...prevSelectedBatches, batchCode];
      }
    });
  };

  const [isFilterModal, setIsFilterModal] = useState(false);
  const [isFilterApplied, setIsFilterApplied] = useState(false);

  const handleCloseFilterModal = () => {
    setIsFilterModal(false);
    setSelectedBatches([]);
  };

  const [batchData, setBatchData] = useState([]);
  const [groupData, setGroupData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await AxiosInstance.post(
          `/employee/student/newFlow`,
          { studentId: user._id },
          {
            headers: {
              Authorization: `Bearer ${user.TOKEN}`,
            },
          }
        );

        if (data.data) {
          let trainersandtrackerdata = data.data?.findStudent?.usersdata?.map(
            stud => {
              return {
                _id: stud?._id,
                role: stud?.role,
                username: stud?.username,
                number: stud?.number,
                studentNotification: stud?.lastmsg?.msg
                  ? stud?.lastmsg?.studentNotification
                  : 0,
                lastmsg: stud?.lastmsg?.msg
                  ? stud?.lastmsg
                  : { studentNotification: 0, createdAt: 0 },
                createdAt: stud?.lastmsg?.msg ? stud?.lastmsg?.createdAt : 0,
                userleft: stud?.userleft,
              };
            }
          );
          setRecentChatUsersList(trainersandtrackerdata);
          setBatchData(data.data?.findStudent?.batchdata);
          setGroupData(data.data?.findStudent?.groupdata);
          setDefaultRecentChatUsersList(data.data?.findStudent);
        }
      } catch (x) {
        if (x?.response?.status === 403) {
          handleError(navigate, socket);
        } else if (x?.response?.status === 404) {
          setNoContent(x?.response?.status);
        }
      }
    };
    fetchData();
  }, []);

  const deleteFilterFunction = async value => {
    try {
      const data = await AxiosInstance.post(
        `/employee/student/newFlow`,
        { studentId: user._id, filter: true, filterCodes: value },
        {
          headers: {
            Authorization: `Bearer ${user.TOKEN}`,
          },
        }
      );

      if (data.data) {
        let trainersandtrackerdata = data.data?.findStudent?.usersdata?.map(
          stud => {
            return {
              _id: stud?._id,
              role: stud?.role,
              username: stud?.username,
              number: stud?.number,
              studentNotification: stud?.lastmsg?.msg
                ? stud?.lastmsg?.studentNotification
                : 0,
              lastmsg: stud?.lastmsg?.msg
                ? stud?.lastmsg
                : { studentNotification: 0, createdAt: 0 },
              createdAt: stud?.lastmsg?.msg ? stud?.lastmsg?.createdAt : 0,
              userleft: stud?.userleft,
            };
          }
        );

        setRecentChatUsersList(trainersandtrackerdata);
      }
    } catch (error) {
      if (error?.response?.status === 403) {
        handleError(navigate, socket);
      }
    }
  };

  const applyFilterFunction = async () => {
    try {
      const data = await AxiosInstance.post(
        `/employee/student/newFlow`,
        { studentId: user._id, filter: true, filterCodes: selectedBatches },
        {
          headers: {
            Authorization: `Bearer ${user.TOKEN}`,
          },
        }
      );

      if (data.data) {
        let trainersandtrackerdata = data.data?.findStudent?.usersdata?.map(
          stud => {
            return {
              _id: stud?._id,
              role: stud?.role,
              username: stud?.username,
              number: stud?.number,
              studentNotification: stud?.lastmsg?.msg
                ? stud?.lastmsg?.studentNotification
                : 0,
              lastmsg: stud?.lastmsg?.msg
                ? stud?.lastmsg
                : { studentNotification: 0, createdAt: 0 },
              createdAt: stud?.lastmsg?.msg ? stud?.lastmsg?.createdAt : 0,
              userleft: stud?.userleft,
            };
          }
        );

        setRecentChatUsersList(trainersandtrackerdata);
      }
    } catch (error) {
      if (error?.response?.status === 403) {
        handleError(navigate, socket);
      }
    }
  };

  return (
    <>
      {/* Filter Option Modal */}
      <MUIModal open={openFilter} onClose={handleCloseFilter}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "40vw",
            height: "18vh",
            bgcolor: "background.paper",
            p: 1,
            display: "flex",
            flexDirection: "column",
            overflowY: "auto",
            borderRadius: 2,
          }}
        >
          <button
            style={{
              background: "transparent",
              position: "absolute",
              right: "5px",
              top: "5px",
            }}
            onClick={() => {
              handleCloseFilter();
            }}
            title="Close"
          >
            <svg width={18.54} height={20} viewBox="0 0 1216 1312">
              <path
                fill="crimson"
                d="M1202 1066q0 40-28 68l-136 136q-28 28-68 28t-68-28L608 976l-294 294q-28 28-68 28t-68-28L42 1134q-28-28-28-68t28-68l294-294L42 410q-28-28-28-68t28-68l136-136q28-28 68-28t68 28l294 294l294-294q28-28 68-28t68 28l136 136q28 28 28 68t-28 68L880 704l294 294q28 28 28 68"
              ></path>
            </svg>
          </button>

          <Box
            display="flex"
            justifyContent="space-around"
            alignItems="center"
            sx={{
              borderBottom: "1px solid #ddd",
              pb: 1,
              // mb: 2,
            }}
          >
            {[
              { label: "Text search", icon: "🔍" },
              { label: "File", icon: "📂" },
              { label: "Poll", icon: "📊" },
              { label: "Links", icon: "🔗" },
            ].map(({ label, icon }) => (
              <Button
                key={label}
                onClick={() => {
                  // setMessages([]);
                  setSearchText("");
                  setActiveSection(label);
                  handleSectionChange(label);
                  handleCloseFilter();
                }}
                sx={{
                  flex: 1,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  color: activeSection === label ? "#f16f1d" : "text.primary",
                  borderBottom:
                    activeSection === label ? "2px solid #f16f1d" : "none",
                  py: 1,
                  mx: 0.5,
                }}
              >
                <span style={{ fontSize: "20px" }}>{icon}</span>
                <span>{label}</span>
              </Button>
            ))}
          </Box>
        </Box>
      </MUIModal>
      <section id={Styles.chatBoxSection}>
        {noContent === 404 ? (
          <div className={Styles.Nodata}>No Such Data</div>
        ) : (
          <article
            className={`${Styles.parent} ${
              isBackActive ? Styles.backActive : ""
            }`}
            style={{ position: "relative" }}
          >
            {isBackActive && (
              <div className={Styles.BackBtn} onClick={handleBackClickBack}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="30"
                  height="30"
                  viewBox="0 0 24 24"
                >
                  <g fill="none">
                    <path d="M24 0v24H0V0zM12.593 23.258l-.011.002l-.071.035l-.02.004l-.014-.004l-.071-.035q-.016-.005-.024.005l-.004.01l-.017.428l.005.02l.01.013l.104.074l.015.004l.012-.004l.104-.074l.012-.016l.004-.017l-.017-.427q-.004-.016-.017-.018m.265-.113l-.013.002l-.185.093l-.01.01l-.003.011l.018.43l.005.012l.008.007l.201.093q.019.005.029-.008l.004-.014l-.034-.614q-.005-.019-.02-.022m-.715.002a.02.02 0 0 0-.027.006l-.006.014l-.034.614q.001.018.017.024l.015-.002l.201-.093l.01-.008l.004-.011l.017-.43l-.003-.012l-.01-.01z" />
                    <path
                      fill="#555"
                      d="M3.283 10.94a1.5 1.5 0 0 0 0 2.12l5.656 5.658a1.5 1.5 0 1 0 2.122-2.122L7.965 13.5H19.5a1.5 1.5 0 0 0 0-3H7.965l3.096-3.096a1.5 1.5 0 1 0-2.122-2.121z"
                    />
                  </g>
                </svg>
              </div>
            )}

            <>
              {/* chat users name displays here */}
              <aside className={`${Styles.chatMenu} ${Styles.convo}`}>
                {/* filter part here here */}
                <div style={{ float: "right", zIndex: "999" }}>
                  {!isFilterApplied && (
                    <button
                      title="apply filters"
                      style={{
                        border: "none",
                        backgroundColor: "transparent",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setIsFilterModal(true);
                      }}
                    >
                      <svg width={24} height={24} viewBox="0 0 48 48">
                        <path fill="#f57c00" d="M29 23H19L7 9h34z"></path>
                        <path
                          fill="#ff9800"
                          d="m29 38l-10 6V23h10zM41.5 9h-35C5.7 9 5 8.3 5 7.5S5.7 6 6.5 6h35c.8 0 1.5.7 1.5 1.5S42.3 9 41.5 9"
                        ></path>
                      </svg>
                    </button>
                  )}
                  &nbsp;
                  {isFilterApplied && (
                    <button
                      title="remove filters"
                      style={{
                        border: "none",
                        backgroundColor: "transparent",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setIsFilterApplied(false);
                        setSelectedBatches([]);
                        toast.info("Filter Removed");
                        let defaultVal = defaultRecentChatUsersList?.usersdata
                          ? defaultRecentChatUsersList?.usersdata
                          : [];
                        setRecentChatUsersList(defaultVal);
                      }}
                    >
                      <svg width={24} height={24} viewBox="0 0 48 48">
                        <path fill="#f57c00" d="M29 23H19L7 9h34z"></path>
                        <path
                          fill="#ff9800"
                          d="m29 38l-10 6V23h10zM41.5 9h-35C5.7 9 5 8.3 5 7.5S5.7 6 6.5 6h35c.8 0 1.5.7 1.5 1.5S42.3 9 41.5 9"
                        ></path>
                        <circle cx={38} cy={38} r={10} fill="#f44336"></circle>
                        <path fill="#fff" d="M32 36h12v4H32z"></path>
                      </svg>
                    </button>
                  )}
                </div>
                {isFilterApplied && (
                  <>
                    <br />
                    <ListContainer>
                      {selectedBatches.map((label, index) => {
                        let icon = null;
                        return (
                          <ListItem key={index}>
                            <Chip
                              size="small"
                              variant="outlined"
                              icon={icon}
                              label={label}
                              onDelete={handleDelete(label)}
                            />
                          </ListItem>
                        );
                      })}
                    </ListContainer>
                  </>
                )}

                {/* modal for filters */}
                <MUIModal open={isFilterModal} onClose={handleCloseFilterModal}>
                  <Box sx={styleModal2} position="relative">
                    <button
                      style={{
                        border: "none",
                        background: "none",
                        position: "absolute",
                        right: "5px",
                        top: "5px",
                        zIndex: "999",
                      }}
                      onClick={handleCloseFilterModal}
                    >
                      <svg width={18.54} height={20} viewBox="0 0 1216 1312">
                        <path
                          fill="crimson"
                          d="M1202 1066q0 40-28 68l-136 136q-28 28-68 28t-68-28L608 976l-294 294q-28 28-68 28t-68-28L42 1134q-28-28-28-68t28-68l294-294L42 410q-28-28-28-68t28-68l136-136q28-28 68-28t68 28l294 294l294-294q28-28 68-28t68 28l136 136q28 28 28 68t-28 68L880 704l294 294q28 28 28 68"
                        ></path>
                      </svg>
                    </button>
                    <h5 style={{ textAlign: "center", color: "#ff7c2a" }}>
                      Select Group/Batch to apply filter
                    </h5>
                    <br />
                    <div>
                      <h6
                        style={{
                          textTransform: "",
                          textDecoration: "underline",
                          color: "#ff7c2a",
                        }}
                      >
                        Batches
                      </h6>
                      <div style={{ maxHeight: "200px", overflow: "auto" }}>
                        {batchData.length === 0 ? (
                          <h6
                            style={{
                              fontSize: "1rem",
                              fontWeight: "400",
                              fontFamily: "Roboto , Helvetica",
                            }}
                          >
                            No Batches Found
                          </h6>
                        ) : (
                          batchData.map((batch, index) => (
                            <FormControlLabel
                              key={index}
                              className={Styles.BatchCheck}
                              control={
                                <Checkbox
                                  color="warning"
                                  checked={selectedBatches.includes(
                                    batch.batchCode
                                  )}
                                  onChange={() =>
                                    handleBatchChange(batch.batchCode)
                                  }
                                />
                              }
                              label={batch.batchCode}
                            />
                          ))
                        )}
                      </div>
                    </div>
                    <Divider />
                    <div>
                      <h6
                        style={{
                          textTransform: "",
                          textDecoration: "underline",
                          color: "#ff7c2a",
                        }}
                      >
                        Groups
                      </h6>
                      <div style={{ maxHeight: "200px", overflow: "auto" }}>
                        {groupData.length === 0 ? (
                          <h6
                            style={{
                              fontSize: "1rem",
                              fontWeight: "400",
                              fontFamily: "Roboto , Helvetica",
                            }}
                          >
                            No Groups Found
                          </h6>
                        ) : (
                          groupData.map((batch, index) => (
                            <FormControlLabel
                              className={Styles.groupCheck}
                              key={index}
                              control={
                                <Checkbox
                                  color="warning"
                                  checked={selectedBatches.includes(
                                    batch.groupCode
                                  )}
                                  onChange={() =>
                                    handleBatchChange(batch.groupCode)
                                  }
                                />
                              }
                              label={batch.groupCode}
                            />
                          ))
                        )}
                      </div>
                    </div>
                    <Button
                      variant="contained"
                      size="small"
                      color="warning"
                      style={{ float: "right" }}
                      onClick={() => {
                        if (selectedBatches.length < 1) {
                          toast.info("Select Batch/Group to apply filter");
                        } else {
                          setIsFilterApplied(true);
                          setIsFilterModal(false);
                          applyFilterFunction();
                        }
                      }}
                    >
                      Apply Filters
                    </Button>
                  </Box>
                </MUIModal>

                <StuConversation
                  batchTrainer={recentChatUsersList}
                  setParticularStudent={setParticularStudent}
                  trainername={selctBatchStud}
                  batchname={batchname}
                  messages={messages}
                  setStop={setStop}
                  setIsPlaying={setIsPlaying}
                  setRecordedAudio={setRecordedAudio}
                  setIsPlaybackActive={setIsPlaybackActive}
                  setIsAudioVisible={setIsAudioVisible}
                  setRecordedChunks={setRecordedChunks}
                  setMsgScroll={setMsgScroll}
                  setMessages={setMessages}
                  particularStudent={particularStudent}
                  setSelectedMessages={setSelectedMessages}
                  setReply={setReply}
                  handleBackClick={handleBackClick}
                  setActiveSection={setActiveSection}
                />
              </aside>
              {/* view message part stars here */}
              <aside
                className={`${Styles.chatBox} ${Styles.convo}`}
                style={{
                  backgroundImage: `url('${bg6}')`,
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                }}
              >
                <div className={Styles.chatBoxWrapper}>
                  {particularStudent?.trainersId ? (
                    <>
                      <div className={Styles.chatBoxTop}>
                        <span
                          style={{
                            marginRight: "20px",
                            position: "absolute",
                            right: "0",
                            top: "12px",
                          }}
                          onClick={handleOpenFilter}
                          title="Search filter"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20px"
                            height="20px"
                            viewBox="0 0 512 512"
                          >
                            <path
                              fill="#f57c00"
                              d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34M208 336c-70.7 0-128-57.2-128-128c0-70.7 57.2-128 128-128c70.7 0 128 57.2 128 128c0 70.7-57.2 128-128 128"
                            ></path>
                          </svg>
                        </span>
                        <StudentName
                          name={particularStudent}
                          online={online}
                          setOnline={setOnline}
                        />
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "end",
                            flexDirection: "column",
                          }}
                        >
                          {" "}
                          {activeSection != "" && (
                            <>
                              {activeSection === "Text search" ? (
                                <Box
                                  sx={{
                                    backgroundColor: "rgba(241, 111, 29, 0.1)",
                                    display: "flex",
                                    alignItems: "center",
                                    width: "100%",
                                    maxWidth: "400px",
                                    borderBottom: `2px solid #f16f1d`,
                                    padding: "2px 8px",
                                    marginRight: "10px",
                                    justifyContent: "space-between",
                                    gap: 1,
                                    position: "relative",
                                    padding: "2px 20px 2px 10px",
                                  }}
                                >
                                  <TextField
                                    variant="standard"
                                    placeholder="Type to search..."
                                    value={searchText}
                                    onChange={e =>
                                      setSearchText(e.target.value)
                                    }
                                    InputProps={{
                                      disableUnderline: true,
                                      style: {
                                        fontSize: "1rem",
                                        color: "#333",
                                      },
                                    }}
                                    sx={{
                                      flexGrow: 1,
                                      "& .MuiInputBase-input": {
                                        padding: 0,
                                      },
                                    }}
                                  />

                                  {!clickSearch && (
                                    <Button
                                      onClick={() => {
                                        SearchTextFilter();
                                        setClickSearch(true);
                                      }}
                                      disabled={!searchText.trim()}
                                      sx={{
                                        backgroundColor: "#f16f1d",
                                        color: "#fff",
                                        textTransform: "none",

                                        fontSize: "0.9rem",
                                        padding: "2px 20px 2px 10px",

                                        borderRadius: "3px",
                                        "&:hover": {
                                          backgroundColor: "#d95e1a",
                                        },
                                        "&:disabled": {
                                          backgroundColor: "#f16f1d",
                                          opacity: 0.6,
                                        },
                                      }}
                                    >
                                      Search
                                    </Button>
                                  )}
                                  {clickSearch && (
                                    <Button
                                      onClick={handleClearSearchFilter}
                                      disabled={!searchText.trim()}
                                      sx={{
                                        backgroundColor: "#f16f1d",
                                        color: "#fff",
                                        textTransform: "none",
                                        borderRadius: "3px",

                                        fontSize: "0.9rem",
                                        padding: "4px 10px",
                                        borderRadius: 0,
                                        "&:hover": {
                                          backgroundColor: "#d95e1a",
                                        },
                                        "&:disabled": {
                                          backgroundColor: "#f16f1d",
                                          opacity: 0.6,
                                        },
                                      }}
                                    >
                                      Clear
                                    </Button>
                                  )}

                                  <span
                                    title="Remove filter"
                                    onClick={handleCancelFilter}
                                    style={{
                                      position: "absolute",
                                      right: "-8px",
                                      top: "-6px",
                                    }}
                                  >
                                    {" "}
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="18px"
                                      height="18px"
                                      viewBox="0 0 16 16"
                                    >
                                      <path
                                        fill="red"
                                        d="M15.1 3.1L12.9.9L8 5.9L3.1.9L.9 3.1l5 4.9l-5 4.9l2.2 2.2l4.9-5l4.9 5l2.2-2.2l-5-4.9z"
                                      ></path>
                                    </svg>
                                  </span>

                                  {/* </Button> */}
                                </Box>
                              ) : (
                                <Box
                                  sx={{
                                    backgroundColor: "#f16f1d38;",
                                    display: "flex",
                                    alignItems: "center",
                                    width: "100%",
                                    maxWidth: "400px",
                                    borderBottom: `2px solid #f16f1d`,
                                    padding: "0px 8px",
                                    margin: "0px 8px",
                                    justifyContent: "space-between",
                                    position: "relative",
                                  }}
                                >
                                  <Typography
                                    sx={{
                                      fontSize: "1rem",
                                      fontWeight: 500,
                                      color: "#f16f1d",
                                      flexGrow: 1,
                                      textAlign: "left",
                                    }}
                                  >
                                    {activeSection} filter applied
                                  </Typography>
                                  <span
                                    title="Remove filter"
                                    onClick={handleCancelFilter}
                                    style={{
                                      position: "absolute",
                                      right: "-8px",
                                      top: "-6px",
                                    }}
                                  >
                                    {" "}
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="18px"
                                      height="18px"
                                      viewBox="0 0 16 16"
                                    >
                                      <path
                                        fill="red"
                                        d="M15.1 3.1L12.9.9L8 5.9L3.1.9L.9 3.1l5 4.9l-5 4.9l2.2 2.2l4.9-5l4.9 5l2.2-2.2l-5-4.9z"
                                      ></path>
                                    </svg>
                                  </span>
                                </Box>
                              )}
                            </>
                          )}
                        </Box>

                        <div
                          className={Styles.chatBoxStore}
                          id="scrollableDiv"
                          ref={scrollRef}
                          style={{
                            height: "78vh",
                            overflow: "auto",
                          }}
                        >
                          {activeSection != "" ? (
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              {messages.length < totalFilterdLength && (
                                <Box
                                  className={Styles.SpinnerBlock}
                                  onClick={handleSpinnerClick}
                                  sx={{
                                    width: 40,
                                    height: 40,
                                    borderRadius: "50%",

                                    backgroundColor: "#f16f1d",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    cursor: "pointer",
                                    animation: isSpinning
                                      ? "spin 1s linear"
                                      : "none",
                                    "@keyframes spin": {
                                      "0%": {
                                        transform: "rotate(0deg)",
                                      },
                                      "100%": {
                                        transform: "rotate(360deg)",
                                      },
                                    },
                                  }}
                                >
                                  {/* Spinner content, like an icon or text */}
                                  <Box
                                    component="span"
                                    title="Loadmore message"
                                    sx={{
                                      width: 20,
                                      height: 20,
                                      border: "3px solid #fff",
                                      borderRadius: "50%",
                                      borderTop: "3px solid transparent",
                                    }}
                                  />
                                </Box>
                              )}
                            </div>
                          ) : (
                            messageCount != msgScroll + lengthmsg && (
                              <div className={Styles.SpinnerBlock}>
                                {" "}
                                <button
                                  onClick={HandleLoadMessages}
                                  className={Styles.Spinner}
                                >
                                  <ImSpinner11 />
                                </button>
                              </div>
                            )
                          )}
                          {messages.length > 0 ? (
                            messages.map((m, ind) => (
                              <Fragment key={ind}>
                                <StuMessage
                                  particularStudent={particularStudent}
                                  message={m}
                                  index={ind}
                                  own={m?.sender === user?._id}
                                  setReply={setReply}
                                  reply={reply}
                                  selectedMessages={selectedMessages}
                                  onClick={handleMessageClick}
                                  setSelectedMessages={setSelectedMessages}
                                  likeShare={likeShare}
                                  setLikeShare={setLikeShare}
                                />
                              </Fragment>
                            ))
                          ) : (
                            <span className={Styles.noConversation}>
                              <p style={{ color: "#f16f1d" }}>No Messages</p>
                            </span>
                          )}
                        </div>
                      </div>

                      {particularStudent?.userleft === false && (
                        <form
                          onSubmit={handelSubmit}
                          className={Styles.FormBlock}
                        >
                          {reply && (
                            <div className={Styles.replyBlock}>
                              <div className={Styles.StatusText1}>
                                {" "}
                                {selectedMessages && (
                                  <>
                                    {/* <div>Reply</div> */}
                                    <div className={Styles.copiedText}>
                                      {selectedMessages[0]?.messages?.text
                                        ? selectedMessages[0]?.messages?.text?.slice(
                                            0,
                                            180
                                          )
                                        : selectedMessages[0]?.messages
                                            ?.files[0]?.filename}
                                    </div>
                                    <div
                                      className={Styles.CrossMark}
                                      onClick={() => CancelReply()}
                                    >
                                      X
                                    </div>
                                  </>
                                )}
                              </div>
                            </div>
                          )}
                          <div className={Styles.chatBoxBottom}>
                            <input
                              name="message"
                              id="message"
                              className="chatMessageInput"
                              placeholder="Write something..."
                              onChange={e => {
                                setNewMessage(e.target.value);
                              }}
                              value={newMessage}
                              onKeyPress={handleKeyPress}
                              onPaste={handlePaste}
                            />
                            <aside
                              style={{ position: "relative" }}
                              className={Styles.UploadfileContainer}
                            >
                              <>
                                {isRecording ? (
                                  ""
                                ) : stop ? (
                                  ""
                                ) : (
                                  <ButtonToolbar>
                                    <Whisper
                                      followCursor
                                      speaker={
                                        <Tooltip>{"Attach file"}</Tooltip>
                                      }
                                    >
                                      <ImAttachment
                                        onClick={handleOpen}
                                      ></ImAttachment>
                                    </Whisper>
                                  </ButtonToolbar>
                                )}

                                <Modal
                                  // backdrop={backdrop}
                                  keyboard={false}
                                  open={open}
                                  onClose={handleClose}
                                >
                                  <Modal.Header>
                                    <Modal.Title style={{ color: "#f16f1d" }}>
                                      File Upload
                                    </Modal.Title>
                                  </Modal.Header>

                                  <Modal.Body>
                                    <FileUpload
                                      PStudent={particularStudent}
                                      setMessages={setMessages}
                                      messages={messages}
                                      setOpen={setOpen}
                                      batchname={batchname}
                                      setArrivalMessage={setArrivalMessage}
                                      setArrivalMessageFile={
                                        setArrivalMessageFile
                                      }
                                      reply={reply}
                                      selectedMessages={selectedMessages}
                                      setReply={setReply}
                                      setSelectedMessages={setSelectedMessages}
                                      pastedImage={pastedImage}
                                      setPastedImage={setPastedImage}
                                    />
                                  </Modal.Body>
                                  <Modal.Footer></Modal.Footer>
                                </Modal>
                              </>
                            </aside>
                            <aside className={Styles.AudioBlock}>
                              <div>
                                {!stop && !isRecording && !isPlaying && (
                                  <Whisper
                                    followCursor
                                    speaker={<Tooltip>{"Start"}</Tooltip>}
                                  >
                                    <main
                                      className={Styles.StartAudio}
                                      onClick={handleStartRecording}
                                      disabled={isRecording}
                                    >
                                      <span>
                                        {/* start */}
                                        <BsFillMicFill />
                                      </span>
                                    </main>
                                  </Whisper>
                                )}
                                <div
                                  className={`${Styles.recordingAnimation} ${
                                    isRecording ? Styles.active : ""
                                  }`}
                                ></div>
                                {isRecording && (
                                  <Whisper
                                    followCursor
                                    speaker={<Tooltip>{"Stop"}</Tooltip>}
                                  >
                                    <main
                                      className={Styles.StopAudio}
                                      onClick={handleStopRecording}
                                      disabled={!isRecording}
                                    >
                                      <span>
                                        {/* stop */}
                                        <BsFillMicMuteFill />
                                      </span>
                                    </main>
                                  </Whisper>
                                )}
                                {stop && (
                                  <Whisper
                                    followCursor
                                    speaker={<Tooltip>{"Play"}</Tooltip>}
                                  >
                                    <main
                                      className={Styles.PlayAudio}
                                      onClick={handlePlayButtonClick}
                                      disabled={isRecording}
                                    >
                                      <span>
                                        {/* play */}
                                        <BsFillPlayCircleFill />
                                      </span>
                                    </main>
                                  </Whisper>
                                )}
                                {/* {stop &&<p>{recordingStatus}</p>} */}
                                {audioPlayer}
                              </div>
                            </aside>
                            {stop && (
                              <div className={Styles.SendAudioBlock}>
                                <Whisper
                                  followCursor
                                  speaker={<Tooltip>{"Delete"}</Tooltip>}
                                >
                                  <span
                                    onClick={handleDeleteButtonClick}
                                    className={Styles.DeleteAudio}
                                  >
                                    <AiFillDelete />
                                  </span>
                                </Whisper>
                                <span
                                  onClick={HandleAudio}
                                  className={Styles.SendAudio}
                                >
                                  send
                                  {/* <AiOutlineSend /> */}
                                </span>
                              </div>
                            )}
                            {!stop && (
                              <div>
                                {loading || isRecording ? (
                                  <button
                                    className="chatSubmitButton"
                                    disabled
                                    onClick={handelSubmitButton}
                                  >
                                    <div className={Styles.loader}>
                                      <RotatingLines
                                        strokeColor="#f16f1d"
                                        strokeWidth="5"
                                        animationDuration="0.75"
                                        width="20"
                                        visible={true}
                                        // z-index={111}
                                      />
                                    </div>
                                  </button>
                                ) : (
                                  <>
                                    <button
                                      type="button"
                                      className="chatSubmitButton"
                                      onClick={handelSubmitButton}
                                    >
                                      Send
                                    </button>
                                    <button
                                      type="submit"
                                      style={{ display: "none" }}
                                      className="chatSubmitButton"
                                      onClick={handelSubmitButton}
                                    >
                                      Send
                                    </button>
                                  </>
                                )}
                              </div>
                            )}
                          </div>
                        </form>
                      )}
                    </>
                  ) : (
                    <span className={Styles.noConversationText}>
                      <p style={{ color: "#f16f1d" }}>
                        Open a conversation to start a chat.
                      </p>
                    </span>
                  )}
                </div>
              </aside>
            </>
          </article>
        )}
      </section>
    </>
  );
};

export default NewChatComp;
